import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, FormLabel } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { chatbtwnuser, manageChatUsers } from "../Redux/actions/adminactions";
import logo from "../Assets/Images/Logo.svg";
import { CgLayoutGrid } from "react-icons/cg";

export default function Chats() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [allUsers, setAllUsers] = useState(null);
  const [chatMesaage, setChatMessage] = useState([]);

  const [secondUserList, setSecondUserList] = useState(null);
  const [chatData, setChatData] = useState({
    SenderId: "",
    ReceiverId: "",
  });
  const [firsrselectionerr, setfirsrselectionerr] = useState("");
  const [seondselectionerr, setseondselectionerr] = useState("");

  const handleClose = () => {
    setShow(false);
    // setChatData({
    //   SenderId: "",
    //   ReceiverId: "",
    // });
  };

  const handlechange = (e) => {
    setChatData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleShow = async () => {
    setShow(true);
    try {
      let data = await dispatch(manageChatUsers());
      console.log(data);
      setAllUsers(data?.payload?.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let SecondUsersList = allUsers?.filter(
      (item) => item.id !== chatData?.SenderId
    );
    setSecondUserList(SecondUsersList);
  }, [chatData]);

  const handleClick = async () => {
    console.log(chatData);
    let isvalid = true;
    try {
      // if (!chatData?.SenderId) {
      //   setfirsrselectionerr("Required*");
      //   isvalid = false;
      // }
      // if (!chatData?.ReceiverId) {
      //   setSecondUserList("Required*");
      //   isvalid = false;
      // }
      // if (isvalid) {
      let data = await dispatch(chatbtwnuser(chatData));
      setChatMessage(data);
      handleClose();

      // }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(chatMesaage?.payload?.messages, "messages");

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Chats</h2>
        </div>
      </div>
      <div className="chat-default">
        <div className="chat-btn">
          <button type="button" onClick={handleShow}>
            View Chat
          </button>
        </div>

        <div className="row mt-20">
          <div className="col-lg-12">
            <div className="job-box">
              {/* <div className="row" style={{ height: "80vh" }}>
                <div
                  className="col-lg-12"
                  style={{ height: "100%", overflowY: "scroll" }}
                >
                  <div className="border-right pe-0"></div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "20px",
                  }}
                >
                  <div
                    className="row "
                    style={{
                      maxHeight: "50vh",
                      overflowY: "scroll",
                    }}
                  ></div>
                </div>
              </div> */}

              <div
                className="row justify-content-center"
                style={{ height: "85vh" }}
              >
                <div
                  className="col-lg-8 position-relative dn"
                  style={{ paddingTop: "20px" }}
                >
                  <div className="row">
                    {chatMesaage?.length == 0 ||
                    (chatMesaage?.payload?.messages &&
                      chatMesaage?.payload?.messages?.length == 0) ? (
                      <p
                        style={{
                          color: "white",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        No Conversation Yet
                      </p>
                    ) : (
                      <>
                        {chatMesaage?.payload?.messages?.length >= 1 &&
                          chatMesaage?.payload?.messages?.map((message) => {
                           console.log(message)
                            

                            return (

                              <>
                                {message?.sender?.id == chatData?.ReceiverId ? (
                                  <div class="hidescroll ">
                                    {message?.type == "message" ? (
                                      <div className="d-flex">
                                        <div class="flex-shrink-0">
                                          <div class="d-flex text-decoration-none">
                                            <img
                                              src={
                                                message?.sender?.profileImage ||
                                                logo
                                              }
                                              alt="..."
                                              class="img-fluid w-40"
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "100%",
                                              }}
                                            />
                                          </div>
                                        </div>

                                        <div class="flex-grow-1 ms-3 pb-4">
                                          <div class="d-flex text-decoration-none">
                                            <ul class="list-unstyled list-inline d-flex">
                                              <li class="list-inline-item fs-16 text-white">
                                                {message?.sender?.fullName}
                                              </li>
                                              <li class="list-inline-item fs-16 text-white-50">
                                                Apr 1, 2024 1:26 PM
                                              </li>
                                            </ul>
                                          </div>
                                          <p class="text-white fs-16 mt--10">
                                            {message?.message}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="d-flex">
                                          <div class="flex-shrink-0">
                                            <div class="d-flex text-decoration-none">
                                              <img
                                                src={
                                                  message?.sender
                                                    ?.profileImage || logo
                                                }
                                                alt="..."
                                                class="img-fluid w-40"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "100%",
                                                }}
                                              />
                                            </div>
                                          </div>

                                          <div class="flex-grow-1 ms-3 pb-4">
                                            <div class="d-flex text-decoration-none">
                                              <ul class="list-unstyled list-inline d-flex">
                                                <li class="list-inline-item fs-16 text-white">
                                                  {message?.sender?.fullName}
                                                </li>
                                                <li class="list-inline-item fs-16 text-white-50">
                                                  Apr 1, 2024 1:26 PM
                                                </li>
                                              </ul>
                                            </div>
                                            <div
                                              class="filter-box"
                                              style={{
                                                backgroundColor:
                                                  "rgb(24, 24, 28)",
                                              }}
                                            >
                                              <div class="row align-items-center">
                                                <div class="col-lg-9">
                                                  <ul class="list-unstyled">
                                                    <li>
                                                      <ul class="list-inline list-inline-item d-flex align-items-center">
                                                        <li class="list-inline-item fs-16 text-white">
                                                          {message?.job
                                                            ?.jobTitle || "N/A"}
                                                        </li>
                                                      </ul>
                                                    </li>
                                                    <li>
                                                      <ul class="list-inline list-inline-item d-flex align-items-center">
                                                        <li class="list-inline-item">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="16"
                                                            viewBox="0 0 24 16"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M10.5 16C10.5 16 9 16 9 14.6667C9 13.3333 10.5 9.33333 16.5 9.33333C22.5 9.33333 24 13.3333 24 14.6667C24 16 22.5 16 22.5 16H10.5ZM16.5 8C17.6935 8 18.8381 7.57857 19.682 6.82843C20.5259 6.07828 21 5.06087 21 4C21 2.93913 20.5259 1.92172 19.682 1.17157C18.8381 0.421427 17.6935 0 16.5 0C15.3065 0 14.1619 0.421427 13.318 1.17157C12.4741 1.92172 12 2.93913 12 4C12 5.06087 12.4741 6.07828 13.318 6.82843C14.1619 7.57857 15.3065 8 16.5 8ZM7.824 16C7.60163 15.5838 7.49074 15.1274 7.5 14.6667C7.5 12.86 8.52 11 10.404 9.70667C9.46364 9.44911 8.48392 9.32316 7.5 9.33333C1.5 9.33333 0 13.3333 0 14.6667C0 16 1.5 16 1.5 16H7.824ZM6.75 8C7.74456 8 8.69839 7.64881 9.40165 7.02369C10.1049 6.39857 10.5 5.55072 10.5 4.66667C10.5 3.78261 10.1049 2.93477 9.40165 2.30964C8.69839 1.68452 7.74456 1.33333 6.75 1.33333C5.75544 1.33333 4.80161 1.68452 4.09835 2.30964C3.39509 2.93477 3 3.78261 3 4.66667C3 5.55072 3.39509 6.39857 4.09835 7.02369C4.80161 7.64881 5.75544 8 6.75 8Z"
                                                              fill="#BDBDBF"
                                                            ></path>
                                                          </svg>
                                                        </li>
                                                        <li class="list-inline-item fs-14 text-white">
                                                          {message?.job
                                                            ?.repsNeeded ||
                                                            "N/A"}
                                                          {` (${message?.job?.commission}%)` ||
                                                            "N/A"}
                                                        </li>
                                                      </ul>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div class="col-lg-3">
                                                  <button
                                                    type="button"
                                                    class="btn btn-outline-danger sm-w-100 w-100"
                                                  >
                                                    Details
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div class="hidescroll d-flex justify-content-end ">
                                    {message?.type == "message" ? (
                                      <div className="d-flex">
                                        <div class="flex-grow-1 ms-3 pb-4">
                                          <div class="d-flex text-decoration-none">
                                            <ul class="list-unstyled list-inline d-flex">
                                              <li class="list-inline-item fs-16 text-white-50">
                                                Apr 1, 2024 1:26 PM
                                              </li>
                                              <li class="list-inline-item fs-16 text-white">
                                                {message?.sender?.fullName ||
                                                  "N/A"}
                                              </li>
                                            </ul>
                                          </div>
                                          <p class="text-white fs-16 mt--10 text-end">
                                             {message?.message}
                                          </p>
                                        </div>
                                        <div class="flex-shrink-0 ms-4">
                                          <div class="d-flex text-decoration-none">
                                            <img
                                              src="https://repconnect.s3.us-east-2.amazonaws.com/profileImages/image_1711605980174.jpg"
                                              alt="..."
                                              class="img-fluid w-40"
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "100%",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="d-flex">
                                          <div class="flex-grow-1 ms-3 pb-4">
                                            <div class="d-flex text-decoration-none">
                                              <ul class="list-unstyled list-inline d-flex me-4">
                                                <li class="list-inline-item fs-16 text-white-50">
                                                  Apr 1, 2024 1:26 PM
                                                </li>
                                                <li class="list-inline-item fs-16 text-white">
                                                  Figo
                                                </li>
                                              </ul>
                                            </div>
                                            <div
                                              class="filter-box"
                                              style={{
                                                backgroundColor:
                                                  "rgb(24, 24, 28)",
                                              }}
                                            >
                                              <div class="row align-items-center">
                                                <div class="col-lg-8">
                                                  <ul class="list-unstyled">
                                                    <li>
                                                      <ul class="list-inline list-inline-item d-flex align-items-center">
                                                        <li class="list-inline-item fs-16 text-white">
                                                          {message?.job
                                                            ?.jobTitle || "N/A"}
                                                        </li>
                                                      </ul>
                                                    </li>
                                                    <li>
                                                      <ul class="list-inline list-inline-item d-flex align-items-center">
                                                        <li class="list-inline-item">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="16"
                                                            viewBox="0 0 24 16"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M10.5 16C10.5 16 9 16 9 14.6667C9 13.3333 10.5 9.33333 16.5 9.33333C22.5 9.33333 24 13.3333 24 14.6667C24 16 22.5 16 22.5 16H10.5ZM16.5 8C17.6935 8 18.8381 7.57857 19.682 6.82843C20.5259 6.07828 21 5.06087 21 4C21 2.93913 20.5259 1.92172 19.682 1.17157C18.8381 0.421427 17.6935 0 16.5 0C15.3065 0 14.1619 0.421427 13.318 1.17157C12.4741 1.92172 12 2.93913 12 4C12 5.06087 12.4741 6.07828 13.318 6.82843C14.1619 7.57857 15.3065 8 16.5 8ZM7.824 16C7.60163 15.5838 7.49074 15.1274 7.5 14.6667C7.5 12.86 8.52 11 10.404 9.70667C9.46364 9.44911 8.48392 9.32316 7.5 9.33333C1.5 9.33333 0 13.3333 0 14.6667C0 16 1.5 16 1.5 16H7.824ZM6.75 8C7.74456 8 8.69839 7.64881 9.40165 7.02369C10.1049 6.39857 10.5 5.55072 10.5 4.66667C10.5 3.78261 10.1049 2.93477 9.40165 2.30964C8.69839 1.68452 7.74456 1.33333 6.75 1.33333C5.75544 1.33333 4.80161 1.68452 4.09835 2.30964C3.39509 2.93477 3 3.78261 3 4.66667C3 5.55072 3.39509 6.39857 4.09835 7.02369C4.80161 7.64881 5.75544 8 6.75 8Z"
                                                              fill="#BDBDBF"
                                                            ></path>
                                                          </svg>
                                                        </li>
                                                        <li class="list-inline-item fs-14 text-white">
                                                          {message?.job
                                                            ?.repsNeeded ||
                                                            "N/A"}
                                                          {` (${message?.job?.commission}%)` ||
                                                            "N/A"}
                                                        </li>
                                                      </ul>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div class="col-lg-4">
                                                  <button
                                                    type="button"
                                                    class="btn btn-outline-danger sm-w-100 w-100"
                                                  >
                                                    Details
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="flex-shrink-0">
                                            <div class="d-flex justify-content-end">
                                              <img
                                                src="https://repconnect.s3.us-east-2.amazonaws.com/profileImages/image_1711605980174.jpg"
                                                alt="..."
                                                class="img-fluid w-40"
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "100%",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="chat-select-popup">
        <Modal.Body>
          <div className="chat-pop-inner">
            <FormLabel>First User</FormLabel>
            <Form.Select
              name="SenderId"
              value={chatData.SenderId}
              onChange={handlechange}
              aria-label="Default select example"
            >
              <option>Select User</option>
              {allUsers?.map((item) => {
                return <option value={item?.id}>{item?.fullName}</option>;
              })}
              <FormLabel className="text-danger">{firsrselectionerr}</FormLabel>
            </Form.Select>

            <FormLabel className="mt-4">Second User</FormLabel>
            <Form.Select
              name="ReceiverId"
              onChange={handlechange}
              value={chatData.ReceiverId}
              aria-label="Default select example"
            >
              <option>Select User</option>

              {secondUserList?.map((item) => {
                return <option value={item?.id}>{item?.fullName}</option>;
              })}

              <FormLabel className="text-danger">{seondselectionerr}</FormLabel>
            </Form.Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Link to="/managechat">View Chat</Link> */}
          <Button variant="secondary" onClick={handleClick}>
            View Chat
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
