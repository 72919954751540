import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserlistByRole,
  getUsers,
  temporaryBanned,
} from "../Redux/actions/adminactions";
import logo from "../Assets/Images/Logo.svg";
import { toast } from "react-toastify";
import { activePath, activepageNumber, activepageNumberSlice, openactivepath, setPageNumber, setUserType } from "../Redux/reducers/userMgmtSlice";
import Pagination from "../Components/Pagination";
import * as XLSX from 'xlsx';
import { Button, Modal } from "react-bootstrap";

export default function UserManagementActive() {
  
  const typeofuserfromRedux = useSelector(
    (state) => state.userdatatype.SelectedUserType
  );
  const pagenumberfromRedux = useSelector(
    (state) => state.activepageNumber.PageNumber
  );
  const pathfromRedux = useSelector(
    (state) => state.activepageNumber.Path
  );
  console.log(pathfromRedux,"redux")
 

  const location=useLocation()

  useEffect(()=>{
  
      dispatch(openactivepath(location?.pathname))

  },[location?.pathname])
 
  const [showd,setshowd] = useState(false)
  const [id,setId] = useState("")
  const [value,setValue] = useState("")
  const [totalpagination, settotalpagination] = useState([]);
  const [pagination, setpagination] = useState(null);
  const [userType, setUsertype] = useState(typeofuserfromRedux);
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(true);
  const [users, setUsers] = useState(null);
  const navigate = useNavigate();
  const [UserTypeData, setUserTypeData] = useState(null);
  const [userMgmtParams, setUserMgmtParams] = useState({
    page: pagenumberfromRedux ? pagenumberfromRedux : "1",
    search: "",
    limit: "10",
  });

 
  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  const handleclick = (id) => {
    navigate("/userprofile", {
      state: {
        id: id,
        path: pathfromRedux
      },
    });
  };


  const handlePages =async (page) => {
  
      
   dispatch(activepageNumber(page));
   
    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  const handleusers = (e) => {
    // setUserMgmtParams((old) => ({ ...old, role: e.target.value }));

    setUsertype(e.target.value);
  };

  const handlelimit = (e) => {
    setUserMgmtParams((old) => ({
      ...old,
      limit: e.target.value ? e.target.value : "10",
    }));
  };

  useEffect(() => {
    dispatch(setUserType(userType));
    const fetchData = async () => {
      try {
        let alluser = await dispatch(
          getUserlistByRole({ ...userMgmtParams, role: userType, banStatus: 1 })
        );
        setUserTypeData(alluser);

        for (
          let i = 1;
          i <= Math.ceil(alluser?.payload?.totalUsers / userMgmtParams.limit);
          i++
        ) {
          setpagination(i);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userType, userMgmtParams, totalpagination, flag]);
  const fetchDataAndExportToExcel = async () => {
    try {
     
      const response = await dispatch(getUserlistByRole({ page:"",limit:"",search:"",role: userType, banStatus: 1}));
      console.log(response?.payload?.users)
  
      
      const allData = response?.payload?.users;

    
    const ws = XLSX.utils.json_to_sheet(allData);

   
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    XLSX.writeFile(wb, 'exported_data.xlsx', { bookType: 'xlsx', bookSST: false, type: 'blob' });
    } catch (error) {
      console.error('Error fetching or exporting data:', error);
    }
  };
  

  const handlebanned = async () => {
    try {
      let data = await dispatch(
        temporaryBanned({
          id: id,
          action: value,
        })
      );

      if (data?.payload?.message === "User unbanned successfully") {
        toast.success(data?.payload?.message);
        setshowd(false)
        setFlag(!flag);
      } else {
        toast.success(data?.payload?.message);
        setFlag(!flag);
        setshowd(false)
        
      }
    } catch (error) {
      console.log("error");
    }
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>User Active</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    onChange={handlesearch}
                    name="search"
                    value={userMgmtParams.search}
                    placeholder="Keyword Search.."
                  />
                </Form.Group>
              </Col>
              <Col xxl={7} xl={7} lg={4} md={5}>
                <Form.Select
                  onChange={handleusers}
                  name="userType"
                  value={userType}
                  aria-label="Default select example"
                >
                  <option value="">All</option>
                  <option value="rep">Sales Reps</option>
                  <option value="seller">Business Owners</option>
                </Form.Select>
              </Col>
              <Col
                xxl={2}
                xl={2}
                lg={4}
                md={3}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />

        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement">All</Link>
              </li>
              <li>
                <Link to="/usermanagementbanned"> Banned</Link>
              </li>
              <li>
                <Link to="/usermanagementactive" className="active-tab">
                  Active
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing {1 + (userMgmtParams?.page - 1) * userMgmtParams?.limit} - {UserTypeData?.payload?.users?.length + (userMgmtParams?.page-1) *userMgmtParams.limit} of {UserTypeData?.payload?.totalUsers} results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlelimit}
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone Number </th>
                <th>Verified/Unverified user</th>
                <th>Profile Image</th>
                {/* <th>Gender</th> */}
                <th>Role</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(UserTypeData?.payload?.users?.length > 0
                ? UserTypeData?.payload?.users
                : users?.payload?.users
              )
                ?.filter((item) =>{
                  console.log(item)
                return   item?.status === 1} )
                .map((item, index) => {
                  const serialNumber =
                    (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                    index +
                    1;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => handleclick(item.id)}
                      >
                        {item?.fullName || "N/A"}
                      </td>

                      <td>{item?.email || "N/A"}</td>
                      <td>{item?.phone || "N/A"}</td>
                      <td>
                        {item?.isEmailVerified ? "Verified" : "Unverified"}
                      </td>
                      <td className="user-img">
                        <img
                          src={item?.profileImage ? item?.profileImage : logo}
                        />
                      </td>
                      {/* <td>{item?.gender}</td> */}
                      <td>
                        {" "}
                        {item?.role === "seller"
                          ? "Bussiness Owner"
                          : item?.role}
                      </td>
                      <td>{item?.banStatus === 1 ? "Active" : "Banned"}</td>
                      <td className="select-box">
                        <Form.Select
                          value={item?.banStatus === 0 ? "ban" : "unban"}
                          onChange={(e) =>{
                              
                              setshowd(true)
                              setId(item?.id)
                              setValue(e.target.value)
                                 
                               }}
                          aria-label="Default select example"
                        >
                          <option value="ban">Temporary Banned</option>
                          <option value="unban">Active</option>
                        </Form.Select>
                      </td>
                    </tr>
                  );
                })}

        
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={showd}  className="delete-popup">
        <Modal.Body>
          <h2>
             
              Are You Sure You Want To Temporary Banned This Account
             
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setshowd(false)}>
            No
          </Button>
          <Button variant="secondary" 
          onClick={handlebanned}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        totalpagination={totalpagination}
        pagination={pagination}
        paginationevent={handlePages}
        pagenumberfromRedux={pagenumberfromRedux}
      />
    </Layout>
  );
}
