import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import adminApi from "../../Services/Adminapi";

const login = createAsyncThunk("login", async (loginObj) => {
  const { data } = await adminApi.post("/login", loginObj);

  if (data?.message == "Admin login successfully.") {
    sessionStorage.setItem("token", data?.token);

 
    return data;
  } 
  
});

export { login };
