import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Forgot Password</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Type Here" />
                    </Form.Group>
                  </div>
                  <Link
                    to="/otp"
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Send OTP
                  </Link>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
