import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useSelector } from "react-redux";
import logo from "../Assets/Images/Logo.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  dashboardData,
  getAllData,
  getUserlistByRole,
  getUsers,
  temporaryBanned,
  userProfile,
} from "../Redux/actions/adminactions";
import { toast } from "react-toastify";
import { FiCloudOff } from "react-icons/fi";
import Pagination from "../Components/Pagination";
import { alluserpath, setPageNumber, setUserType } from "../Redux/reducers/userMgmtSlice";
import * as XLSX from 'xlsx';
import { Button, Modal } from "react-bootstrap";

export default function UserManagement() {
  const typeofuserfromRedux = useSelector(
    (state) => state.userdatatype.SelectedUserType
  );
  const pagenumberfromRedux = useSelector(
    (state) => state.pageNumber.PageNumber
  );
  const pathfromRedux = useSelector(
    (state) => state.pageNumber.Path
  );
  console.log(pathfromRedux,"redux")


  const location = useLocation()
 

  useEffect(()=>{
    dispatch(alluserpath(location?.pathname))

  },[location?.pathname])

  const [userType, setUsertype] = useState("");
  const [showd,setshowd] = useState(false)
  const [id,setId] = useState("")
  const [value,setValue] = useState("")
 const dispatch = useDispatch();
  const [flag, setFlag] = useState(true);
  const [totalpagination, settotalpagination] = useState([]);
  const [pagination, setpagination] = useState(null);

  const [users, setUsers] = useState(null);
  const [UserTypeData, setUserTypeData] = useState(null);
  const navigate = useNavigate();

  const [userMgmtParams, setUserMgmtParams] = useState({
    page: pagenumberfromRedux ? pagenumberfromRedux : "1",
    search: "",
    limit: "10",
    role: "",
  });
  console.log(users, "allusers");

  const handlePages = (page) => {
    dispatch(setPageNumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  const fetchDataAndExportToExcel = async () => {
    try {
     
      const response = await dispatch(getUsers({page:"",search:"",limit:""}));
      console.log(response?.payload?.users)
  
      
      const allData = response?.payload?.users;

    
    const ws = XLSX.utils.json_to_sheet(allData);

   
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    XLSX.writeFile(wb, 'exported_data.xlsx', { bookType: 'xlsx', bookSST: false, type: 'blob' });
    } catch (error) {
      console.error('Error fetching or exporting data:', error);
    }
  };

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await dispatch(getUsers(userMgmtParams));
        
        setUsers(userData);

        for (
          let i = 1;
          i <= Math.ceil(userData?.payload?.totalUser / userMgmtParams.limit);
          i++
        ) {
          settotalpagination(i);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    


  }, [userMgmtParams, totalpagination, flag]);

  const handleclick = (id) => {
    

    navigate("/userprofile", {
      state: {
        id: id,
        path:pathfromRedux
      },
    });
  };

  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  const handlelimit = (e) => {
    setUserMgmtParams((old) => ({
      ...old,
      limit: e.target.value ? e.target.value : "10",
    }));
  };

  const handlebanned = async () => {
    try {
      let data = await dispatch(
        temporaryBanned({
          id: id,
          action: value,
        })
      );

      if (data?.payload?.message === "User unbanned successfully") {
        toast.success(data?.payload?.message);
        setshowd(false)
        setFlag(!flag);
      } else {
        toast.success(data?.payload?.message);
        setFlag(!flag);
        setshowd(false)
        
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleusers = (e) => {
    setUsertype(e.target.value);
  };

  useEffect(() => {
    dispatch(setUserType(userType));
    const fetchData = async () => {
      try {
        let alluser = await dispatch(
          getAllData({ ...userMgmtParams, role: userType })
        );
        console.log(alluser)
        setUserTypeData(alluser);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userType, userMgmtParams, flag]);
  

  useEffect(() => {
    if (UserTypeData?.payload?.users?.length > 0) {
      for (
        let i = 1;
        i <=
        Math.ceil(UserTypeData?.payload?.totalUsers / userMgmtParams.limit);
        i++
      ) {
        setpagination(i);
      }
    }
  }, [UserTypeData?.payload?.users, pagination]);
  console.log(UserTypeData,"====================================")

 

  return (
    
    
        <Layout>
          <div className="right-top">
            <div className="heading-top">
              <h2>User Management</h2>
            </div>
            <hr />
            <Container fluid>
              <div className="cmn-top-fields">
                <Row>
                  <Col xxl={3} xl={3} lg={4} md={4}>
                    <Form.Group>
                      <Form.Control
                        onChange={handlesearch}
                        type="search"
                        name="search"
                        value={userMgmtParams.search}
                        placeholder="Keyword Search.."
                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={7} xl={7} lg={4} md={5}>
                    <Form.Select
                      onChange={handleusers}
                      name="userType"
                      value={userType}
                      // value={userMgmtParams.role}
                      aria-label="Default select example"
                    >
                      <option value="">All</option>
                      <option value="rep">Sales Reps</option>
                      <option value="seller">Business Owners</option>
                    </Form.Select>
                  </Col>
                  <Col
                    xxl={2}
                    xl={2}
                    lg={4}
                    md={3}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div className="cmn-btn">
                      <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <hr />
            <Container fluid>
              <div className="product-tab">
                <ul>
                  <li>
                    <Link to="/UserManagement" className="active-tab">
                      All
                    </Link>
                  </li>
                  <li>
                    <Link to="/usermanagementbanned"> Banned</Link>
                  </li>
                  <li>
                    <Link to="/usermanagementactive">Active</Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
          <Container fluid>
            <div className="results-sec">
              <Row>
                <Col lg={6} className="d-flex align-items-center">
                  <div className="show-results">
                    Showing{" "}
                    {1 + (userMgmtParams?.page - 1) * userMgmtParams?.limit} -{" "}
                    {UserTypeData?.payload?.users?.length +
                      (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                    of {UserTypeData?.payload?.totalUsers} results
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="select-result d-flex align-items-center justify-content-end">
                    <span>Results per page</span>{" "}
                    <Form.Select
                      onChange={handlelimit}
                      value={userMgmtParams.limit}
                      aria-label="Default select example"
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>

          <Container fluid className="px-0">
            <div class="table-responsive main">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone Number </th>
                    <th>Verified/Unverified user</th>
                    <th>Profile Image</th>

                    <th>Role</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(UserTypeData?.payload?.users?.length > 0
                    ? UserTypeData?.payload?.users
                    : users?.payload?.users
                  )?.map((item, index) => {
                    const serialNumber =
                      (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                      index +
                      1;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleclick(item.id)}
                        >
                          {item?.fullName || "N/A"}
                        </td>

                        <td>{item?.email || "N/A"}</td>
                        <td>{item?.phone|| "N/A"}</td>
                        <td>
                          {item?.badgeVerificationStatus==0 ? "Verified" : "Unverified"}
                        </td>
                        <td className="user-img ">
                          <img
                            src={item?.profileImage ? item?.profileImage : logo}
                          />
                        </td>
                        {/* <td>{item?.gender}</td> */}
                        <td>
                          {item?.role === "seller"
                            ? "Bussiness Owner"
                            : item?.role}
                        </td>
                        {/* <td>{item?.banStatus === 1 ? "Active" : "Banned"}</td> */}
                        <td>{item?.status === 0 ? "In-active" : item.banStatus === 1 ? "Active" : "Banned"}</td>
                        <td className="select-box">
                          <Form.Select
                            name="action"
                            value={item?.banStatus === 0 ? "ban" : "unban"}
                            onChange={(e) =>{
                              
                              setshowd(true)
                              setId(item?.id)
                              setValue(e.target.value)
                                 
                               }}
                            aria-label="Default select example"
                          >
                            <option value="ban">Temporary Banned </option>
                            <option value="unban">Active</option>
                          </Form.Select>
                        </td>
                      </tr>
                    );
                  })}

                 
                </tbody>
              </Table>
            </div>
          </Container>

          <Modal show={showd}  className="delete-popup">
        <Modal.Body>
          <h2>
           
              Are You Sure You Want To {value=="ban" ? "Temporary Banned" : "Activate" }  This Account
             
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setshowd(false)}>
            No
          </Button>
          <Button variant="secondary" 
          onClick={handlebanned}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>


          <Pagination
            totalpagination={totalpagination}
            pagination={pagination}
            paginationevent={handlePages}
            pagenumberfromRedux={pagenumberfromRedux}
          />
        </Layout>
     
     
    
     
  );
}
