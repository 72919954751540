import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import { addnotification } from "../Redux/actions/adminactions";
import { toast } from "react-toastify";

export default function AddNotification() {
  const dispatch = useDispatch();
  const [addNotification, setAddNotification] = useState({
    title: "",
    desc: "",
    to: "",
  });
  const [error, setError] = useState({
    titleerr: "",
    descerr: "",
    toerr: "",
  });

  const handlechange = (e) => {
    setAddNotification((old) => ({ ...old, [e.target.name]: e.target.value }));
    setError((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };

  const handleAdd = () => {
    console.log("first");
    validation();
  };

  const validation = async () => {
    let isvalid = true;
    let err = {
      titleerr: "",
      descerr: "",
      toerr: "",
    };

    if (!addNotification.title) {
      err.titleerr = "Please enter title";
      isvalid = false;
    }
    if (!addNotification.to) {
      err.toerr = "Please select";
      isvalid = false;
    }
    if (!addNotification.desc) {
      err.descerr = "Please enter description";
      isvalid = false;
    }
    if (!isvalid) {
      setError(err);
    }
    if (isvalid) {
      try {
        const data = await dispatch(addnotification(addNotification));
        console.log(data);
        if (data?.payload?.status === 200) {
          
          setAddNotification({ title: "", desc: "", to: "" });
          window.location.href="/Notification"
        } else {
          toast.error(data?.payload?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add Notification</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Notification">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Col lg={8}>
            <div className="customer-form-new">
              <h2>BASIC DETAILS</h2>
              <hr className="cmn-border" />
              <div className="customer-form-inner">
                <Row>
                  <Col lg={12}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Select Users</Form.Label>

                        <Form.Select
                          name="to"
                          onChange={handlechange}
                          value={addNotification.to}
                          aria-label="Default select example"
                        >
                        <option >Select</option>
                          <option value="0">All Users</option>
                          <option value="1">All Sales Reps</option>
                          <option value="2">All Business Owners</option>
                        </Form.Select>
                        <Form.Label className="text-danger">
                          {error.toerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        onChange={handlechange}
                        value={addNotification.title}
                        type="text"
                        placeholder="Add Title"
                      />
                      <Form.Label className="text-danger">
                        {error.titleerr}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="desc"
                        onChange={handlechange}
                        value={addNotification.desc}
                        as="textarea"
                        rows={5}
                        placeholder="Add Description"
                      />
                      <Form.Label className="text-danger">
                        {error.descerr}
                      </Form.Label>
                    </Form.Group>
                  </Col>

                  <Col lg={12} className="d-flex justify-content-center mt-3">
                    <button
                      onClick={handleAdd}
                      type="button"
                      className="add-btn"
                    >
                      Notify
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
