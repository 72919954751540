import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import Jobs from "../Components/Layout/elements/ChartJobs";
import Users from "../Components/Layout/elements/ChartUsers";
import ChartSubscription from "../Components/Layout/elements/ChartSubscription";
import { useDispatch, useSelector } from "react-redux";
import { setUserType } from "../Redux/reducers/userMgmtSlice";
import { getAllData, getJobs } from "../Redux/actions/adminactions";


export default function Dashboard() {
  
 
  const [seller,setSeller] = useState("")
  const [reps,setReps] = useState("")
  const [jobs,setJobs] = useState("")
  

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(setUserType("seller"));
    const fetchData = async () => {
      try {
        let alluser = await dispatch(
          getAllData({ page:"",limit:"",search:"", role: "seller"})
        );
        setSeller(alluser?.payload?.totalUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // dispatch(setUserType("reps"));
    const fetchData = async () => {
      try {
        let alluser = await dispatch(
          getAllData({ page:"",limit:"",search:"", role: "rep"})
        );
        // console.log(alluser)
        setReps(alluser?.payload?.totalUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  
  useEffect(() => {
    const fetchdata = async () => {
      try {
        let jobsData = await dispatch(getJobs({page:"",limit:"",search:""}));
        console.log(jobsData)
        setJobs(jobsData?.payload?.totalJobs);
      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, []);
  

  

  return (
    <>
     
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Dashboard</h2>
          </div>
        </div>
        <Container fluid>
          <div className="dashboard-items">
            <Row>
              <Col xxl={12} xl={12} lg={12}>
                <div className="dashbox-inner-wrap">
                  
                  <Row>
                    <Col>
                      <div className="dash-inner-boxes">
                        <h6>Total Sales Reps</h6>
                        <h4>{reps}</h4>
                        <p>1000 - Average users per day</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="dash-inner-boxes">
                        <h6>Total Business Owners</h6>
                        <h4>{seller}</h4>
                        <p>1000 - Average users per day</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="dash-inner-boxes">
                        <h6>Total Jobs Posted</h6>
                        <h4>{jobs}</h4>
                        <p>1000 - Average users per day </p>
                      </div>
                    </Col>

                    <Col>
                      <div className="dash-inner-boxes">
                        <h6>Total Subscribed Users</h6>
                        <h4>0</h4>
                        <p>1000 - Average users per day</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              {/* <Col xxl={3} xl={4} lg={3}>
                <Row>
                  <Col>
                    <div className="dash-inner-boxes">
                      <h6>Total Sales Reps</h6>
                      <h4>{salesReps?.length}</h4>
                      <p>1000 - Average users per day</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="dash-inner-boxes">
                      <h6>Total Business Owners</h6>
                      <h4>{BussinessOwner?.length}</h4>
                      <p>1000 - Average users per day</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="dash-inner-boxes">
                      <h6>Total Jobs Posted</h6>
                      <h4>{data?.length}</h4>
                      <p>1000 - Average users per day </p>
                    </div>
                  </Col>
                  {/* <Col>
                    <div className="dash-inner-boxes">
                      <h6>Total Messages sen</h6>
                      <h4>30 Millions</h4>
                      <p>1000 - Average users per day</p>
                    </div>
                  </Col> */}
              {/* <Col>
                <div className="dash-inner-boxes">
                  <h6>Total Subscribed Users</h6>
                  <h4>{salesReps?.length}</h4>
                  <p>1000 - Average users per day</p>
                </div>
              </Col> */}
            </Row>
          </div>
          {/* </Col> */}
          {/* <Col xxl={3} xl={4} lg={3}>
                  <div className="dashbox-side">
                    <h2>ACCOUNTS OVERVIEW</h2>
                    <div className="dash-inner-boxes">
                      <h6>ACCOUNT CURRENT </h6>
                      <h4>£2,202,330.00</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
          {/* </Row> */}

          <Row className="dash-bottom mb-4">
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph">
                <h6>Jobs</h6>
                <Jobs />
              </div>
            </Col>
            {/* <Col xxl={4} xl={4} lg={4}>
                  <div className="dashbox-side">
                    <h2>MEMBERS </h2>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL MEMBERS </h6>
                      <h4>10,292</h4>
                      <p>10% increase from last month</p>
                    </div>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL CLUBS </h6>
                      <h4>761</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
            <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph ">
                <h6>Users</h6>
                <Users />
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph ">
                <h6>Subscription</h6>
                <ChartSubscription />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
      {/* )} */}
    </>
  );
}
