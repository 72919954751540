
import { createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { deleteUser, userProfile } from "../actions/adminactions";


const initialState = {
  userinfo: null,
};

export const userProfileSlice = createSlice({
  name: "userMgmtSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userProfile.fulfilled, (state, action) => {
       
        

      if (action.payload) {
        state.userinfo = action?.payload;
        // window.location.href="/userprofile"
      } else {
        toast.error(action?.payload?.message);
      }
    });

    
    
  },
});

export default userProfileSlice.reducer;

export const userActivationSlice = createSlice({
  name: "userActivationSlice",
  initialState:{
    status:null
  },
  reducers: {
    ActivationStatus:(state,action)=>{
     state.status= action.payload
    }

  },
  
    
    
  
});

export const {ActivationStatus} = userActivationSlice.actions
