import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";

export default function AllProfile() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>All Management</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/userManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={6}>
            <div className="user-profile-all">
              <h3>Customer</h3>
              <Row>
                <Col lg={6}>
                  <Figure>
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src={require("../Assets/Images/user.png")}
                    />
                    <Figure.Caption>
                      <h2>John Smith</h2>

                      <p>
                        <span>Email - </span>email@domain.com
                      </p>
                      <p>
                        <span>Phone Number - </span>+447380 605060
                      </p>
                    </Figure.Caption>
                  </Figure>
                </Col>

                <Col lg={12}>
                  <div className="property-main">
                    <h2>Details of the property that he have selected.</h2>
                    <div className="property-name-show">
                      <div>
                        <img src={require("../Assets/Images/property.png")} />
                      </div>
                      <div>
                        <h2>Dylan’s Villa</h2>
                        <h3>22 Shelton Street, London SW6</h3>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="dashboard-items">
                    <Row>
                      <Col xxl={6} xl={6} lg={6}>
                        <div className="dash-inner-boxes">
                          <h6>Total Orders</h6>
                          <h4>100 Millions</h4>
                          <p>Average users per day</p>
                        </div>
                      </Col>
                      <Col xxl={6} xl={6} lg={6}>
                        <div className="dash-inner-boxes">
                          <h6> Completed Orders</h6>
                          <h4>30 Millions</h4>
                          <p>Average users per day</p>
                        </div>
                      </Col>
                      <Col xxl={6} xl={3} lg={6}>
                        <div className="dash-inner-boxes">
                          <h6>Rejected Orders</h6>
                          <h4>30 Millions</h4>
                          <p>Average users per day</p>
                        </div>
                      </Col>
                      <Col xxl={6} xl={3} lg={6}>
                        <div className="dash-inner-boxes">
                          <h6>Submitted Orders</h6>
                          <h4>30 Millions</h4>
                          <p>Average users per day</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className="user-profile-all">
              <h3>Vendor</h3>
              <Row>
                <Col lg={6}>
                  <Figure>
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src={require("../Assets/Images/user.png")}
                    />
                    <Figure.Caption>
                      <h2>John Smith</h2>
                      <p>
                        <span>Email - </span>email@domain.com
                      </p>
                      <p>
                        {" "}
                        <span>Phone - </span>+447380 605060
                      </p>

                      <p>
                        {" "}
                        <span>Company Name - </span>xyz Company
                      </p>
                      <p>
                        {" "}
                        <span>Position - </span>Cleaner
                      </p>
                      <p>
                        {" "}
                        <span>Full Address - </span>22 Shelton Street, London{" "}
                      </p>
                      <p>
                        <span>City - </span>Illford
                      </p>
                      <p>
                        <span>Postcode - </span>SW6
                      </p>
                      <p>
                        <span>Country - </span>United Kingdom
                      </p>
                    </Figure.Caption>
                  </Figure>
                </Col>
                <Col lg={6}>
                  <div className="cmn-top-fields">
                    <Form.Select aria-label="Default select example">
                      <option>All</option>
                      <option value="1">Property 1</option>
                      <option value="2">Property 2</option>
                      <option value="3">Property 3</option>
                    </Form.Select>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="dashboard-items">
                    <Row>
                      <Col xxl={6} xl={6} lg={6}>
                        <div className="dash-inner-boxes">
                          <h6>Total Orders</h6>
                          <h4>100 Millions</h4>
                          <p>Average users per day</p>
                        </div>
                      </Col>
                      <Col xxl={6} xl={6} lg={6}>
                        <div className="dash-inner-boxes">
                          <h6> Completed Orders</h6>
                          <h4>30 Millions</h4>
                          <p>Average users per day</p>
                        </div>
                      </Col>
                      <Col xxl={6} xl={6} lg={6}>
                        <div className="dash-inner-boxes">
                          <h6>Rejected Orders</h6>
                          <h4>30 Millions</h4>
                          <p>Average users per day</p>
                        </div>
                      </Col>
                      <Col xxl={6} xl={6} lg={6}>
                        <div className="dash-inner-boxes">
                          <h6>Submitted Orders</h6>
                          <h4>30 Millions</h4>
                          <p>Average users per day</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={12}>
            <div className="user-profile-all">
              <h3>Manager</h3>
              <Row>
                <Col lg={6}>
                  <Figure>
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src={require("../Assets/Images/user.png")}
                    />
                    <Figure.Caption>
                      <h2>John Smith</h2>
                      <p>
                        <span>Email - </span>email@domain.com
                      </p>
                      <p>
                        {" "}
                        <span>Phone - </span>+447380 605060
                      </p>

                      <p>
                        {" "}
                        <span>Full Address - </span>22 Shelton Street, London{" "}
                      </p>
                      <p>
                        <span>City - </span>Illford
                      </p>
                      <p>
                        <span>Postcode - </span>SW6
                      </p>
                      <p>
                        <span>Country - </span>United Kingdom
                      </p>
                    </Figure.Caption>
                  </Figure>
                </Col>

                <Col lg={12}>
                  <div class="table-responsive">
                    <Table size="sm" className="table-cmn">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Property Image</th>
                          <th>Property Name</th>
                          <th>Address</th>
                          <th>City </th>
                          <th>Postal Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td className="user-img">
                            <img src={require("../Assets/Images/user.png")} />
                          </td>
                          <td>#123 street , New York, NY</td>
                          <td>New York</td>
                          <td>New York</td>
                          <td>WSl</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td className="user-img">
                            <img src={require("../Assets/Images/user.png")} />
                          </td>
                          <td>#321 street , London, Uk</td>
                          <td>New York</td>
                          <td>New York</td>
                          <td>WSl</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td className="user-img">
                            <img src={require("../Assets/Images/user.png")} />
                          </td>
                          <td>#456 street , Illford, Uk</td>
                          <td>New York</td>
                          <td>New York</td>
                          <td>WSl</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td className="user-img">
                            <img src={require("../Assets/Images/user.png")} />
                          </td>
                          <td>#789 street , Sydney, Australia</td>
                          <td>New York</td>
                          <td>New York</td>
                          <td>WSl</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td className="user-img">
                            <img src={require("../Assets/Images/user.png")} />
                          </td>
                          <td>#321 street , London, Uk</td>
                          <td>New York</td>
                          <td>New York</td>
                          <td>WSl</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td className="user-img">
                            <img src={require("../Assets/Images/user.png")} />
                          </td>
                          <td>#321 street , London, Uk</td>
                          <td>New York</td>
                          <td>New York</td>
                          <td>WSl</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td className="user-img">
                            <img src={require("../Assets/Images/user.png")} />
                          </td>
                          <td>#321 street , London, Uk</td>
                          <td>New York</td>
                          <td>New York</td>
                          <td>WSl</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
