import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSupportStatus,
  createNotes,
  getSupportById,
} from "../Redux/actions/adminactions";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { CgLayoutGrid } from "react-icons/cg";

export default function TicketDetail() {
  const dispatch = useDispatch();
  const supportById = useSelector((state) => state.Support.getSupportById);
  console.log(supportById);
  const { id } = useParams();
  console.log(id);
  const [flag, setflag] = useState(true);
  const [notes, setNotes] = useState(null);

  useEffect(() => {
    dispatch(getSupportById(id));
  }, [flag]);

  console.log(notes, "notes");

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Ticket 000001</h2>
          </div>
          <div>
            <div className="date-created">
              <h4>
                REGISTERED:{" "}
                <span>
                  {" "}
                  {new Date(
                    supportById?.support?.createdAt
                  ).toLocaleDateString()}
                </span>
              </h4>
              <h4>
                LAST ACTION:{" "}
                <span>
                  {" "}
                  {new Date(
                    supportById?.support?.updatedAt
                  ).toLocaleDateString()}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12}>
              <div className="product-tab-left">
                <Link to="/support">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2>MEMBER INFORMATION</h2>
                  <Row>
                    <Col lg={6}>
                      <div className="member-info-inner">
                        <h2>DETAILS</h2>
                        <div className="member-inner">
                          <p>
                            Member:{" "}
                            <span>
                              {supportById?.support?.userId?.fullName}
                            </span>
                          </p>
                          <p>
                            Email:{" "}
                            <span>{supportById?.support?.userId?.email}</span>
                          </p>
                          <p>
                            Phone:{" "}
                            <span>{supportById?.support?.userId?.phone}</span>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="member-info-inner">
                        <h2>ADDRESS</h2>
                        <div className="address-main">
                          <p>
                            {supportById?.support?.userId?.city} <br />
                            {supportById?.support?.userId?.address}
                            <br /> {supportById?.support?.userId?.country}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>TICKET DETAILS </h2>
                  <hr />
                  <div className="admin-box-main">
                    <div className="admin-box-inner">
                      <div className="admin-box-top">
                        <h3 className="text-start">
                          {new Date(
                            supportById?.support?.updatedAt
                          ).toLocaleDateString()}
                          <span>
                            {new Date(
                              supportById?.support?.updatedAt
                            ).toLocaleTimeString()}
                          </span>
                        </h3>
                      </div>
                      <p className="text-start">
                        {supportById?.support?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>NOTES </h2>
                  <hr />
                  <div className="admin-box-main">
                    <div className="admin-box-inner">
                      {supportById?.support?.notes?.length > 0 ? (
                        supportById?.support?.notes?.map((note) => {
                          return (
                            <>
                              <div className="admin-box-top">
                                <h3>
                                  {note?.admin_name}{" "}
                                  <span>
                                    {" "}
                                    {new Date(
                                      note?.createdAt
                                    ).toLocaleDateString()}
                                  </span>{" "}
                                  <span>
                                    {" "}
                                    {new Date(
                                      note?.createdAt
                                    ).toLocaleTimeString()}
                                  </span>
                                </h3>
                              </div>

                              <p>{note?.notes}</p>
                            </>
                          );
                        })
                      ) : (
                        <p style={{ color: " #747373" }}>
                          No data available yet.
                        </p>
                      )}
                    </div>
                    <hr />

                    <hr />
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        dispatch(
                          createNotes({
                            id: id,
                            notes: notes,
                          })
                        ).then((res) => {
                          if (
                            res?.payload?.message == "Note added successfully"
                          ) {
                            setNotes("")
                            toast.success(res?.payload?.message);
                       
                            setflag(!flag);
                            
                          } else {
                            toast.error(res?.payload?.message);
                          }
                        });
                      }}
                    >
                      <div className="admin-box-inner">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                          // name="notes"
                         
                        >
                          <Form.Control
                           value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value);
                          }}
                            as="textarea"
                            rows={3}
                            placeholder="Type Here"
                          />
                        </Form.Group>
                                      <div className="d-flex justify-content-center">
                        <Button
                        type="submit"
                          className="account-btn "
                          style={{ width: "120px" }}
                        >
                          Save
                        </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>STATUS</h2>
                <Form.Select
                  onChange={(e) => {
                    dispatch(
                      changeSupportStatus({
                        id: supportById?.support?._id,
                        status: e.target.value,
                      })
                    ).then((res) => {
                      console.log(res);
                      if (
                        res?.payload?.message ==
                        "Support status changed successfully"
                      ) {
                        toast.success(res?.payload?.message);
                        setflag(!flag);
                      } else {
                        toast.error(res?.payload?.message);
                      }
                    });
                  }}
                  aria-label="Default select example"
                  value={supportById?.support?.status}
                >
                  <option value="0">Opened</option>
                  <option value="1">Closed</option>
                </Form.Select>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
