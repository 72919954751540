import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

export default function Pagination({
  totalpagination,
  paginationevent,
  pagination,
  pagenumberfromRedux
}) {
  const handlePageClick = (event) => {
    paginationevent(event.selected + 1);
  };

  

  

  // const pagenumberfromRedux = useSelector(
  //   (state) => state.pageNumber.PageNumber
  // );

  // const getPageItemClassName = (pageNumber) => {
  //   return pageNumber === pagenumberfromRedux ? "selected" : "";
  // };

  return (
    <div className="pagination-wrapper next-btn-fix">
      <ReactPaginate
        breakLabel="..."
        nextLabel={"next"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pagination !== null ? pagination : totalpagination}
        previousLabel={"previous"}
        renderOnZeroPageCount={null}
        initialPage={pagenumberfromRedux ? pagenumberfromRedux-1 : 0}
        // containerClassName={'pagination justify-content-center'}
        //   pageClassName={'page-item'}
        //   pageLinkClassName={'page-link'}
        //   previousClassName={'page-item'}
        //   previousLinkClassName={'page-link'}
        //   nextClassName={'page-item'}
        //   nextLinkClassName={'page-link'}
        //   breakClassName={'page-item'}
        //   breakLinkClassName={'page-link'}
        // activeClassName={"selected"}
        // pageClassName={(pageNumber) =>
        //   `page-item ${getPageItemClassName(pageNumber + 1)}`
        // }
      />
    </div>
  );
}
