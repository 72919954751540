import React, { useState } from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ChangeUserPassword } from "../Redux/actions/adminactions";
import { toast } from "react-toastify";

export default function ChangePassword() {
  const dispatch = useDispatch();

  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    oldPassworderr: "",
    newPassworderr: "",
    confirmPassworderr: "",
  });

  const handlechange = (e) => {
    setPassword((old) => ({ ...old, [e.target.name]: e.target.value }));
    setError((old)=>({...old,[`${e.target.name}err`] : ""}))
  };

  const handleclick = (e) => {
    e.preventDefault();
    validation();
  };

  const validation = async () => {
    let isvalid = true;
    let err = {
      oldPassworderr: "",
      newPassworderr: "",
      confirmPassworderr: "",
    };

    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!password.oldPassword) {
      err.oldPassworderr = "Required*";
      isvalid = false;
    }

    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!password.newPassword) {
      err.newPassworderr = "Required*";
      isvalid = false;
    }
    // else if (!passwordRegex.test(password.newPassword)) {
    //   err.newPassworderr = "Please Enter Valid Password";
    //   isvalid = false;
    // }
    
    if (!password.confirmPassword) {
      err.confirmPassworderr = "Required*";
      isvalid = false;
    }
    

    if (!isvalid) {
      setError(err);
    }
    if (isvalid) {
      try {
        const data = await dispatch(ChangeUserPassword(password));
        console.log(data);
        if (data?.payload?.message === "Password updated successfully") {
          toast.success(data?.payload?.message);
          setPassword({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        } else {
          toast.error(data?.payload?.message);
        }
      } catch (error) {
        toast.error("Error deleting user");
      }
    }
  };

  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top text-white">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border- p-5 rounded-card customer-form-new">
                <h2 className="text-center text-white">Change Password</h2>

                <Form className="change-password-form px-5">
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label >Old Password</Form.Label>
                      <Form.Control
                        onChange={handlechange}
                        name="oldPassword"
                        value={password.oldPassword}
                        type="password"
                        placeholder="Old Password"
                      />
                       <Form.Label className="text-danger">{error.oldPassworderr}</Form.Label>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        onChange={handlechange}
                        name="newPassword"
                        value={password.newPassword}
                        type="password"
                        placeholder="New Password"
                      />
                      <Form.Label  className="text-danger">{error.newPassworderr}</Form.Label>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        onChange={handlechange}
                        name="confirmPassword"
                        value={password.confirmPassword}
                        type="password"
                        placeholder="Confirm Password"
                      />
                      <Form.Label  className="text-danger">{error.confirmPassworderr}</Form.Label>
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mt-4  mb-5 model-btn text-center"
                    controlId="formGridAddress2"
                  >
                    <div class="cmn-btn">
                      <button type="button" onClick={handleclick}>
                        SAVE
                      </button>
                    </div>
                  </Form.Group>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
