import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function AddServices() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add Service</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ManageServices">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="height-set">
        <Row className="justify-content-center ">
          <Col lg={8}>
            <div className="customer-form-new mb-4">
              <h2>SERVICE DETAILS</h2>
              <hr className="cmn-border" />
              <div className="customer-form-inner">
                <Row>
                  <Col lg={4}>
                    <Form.Group className="mb-3 image-upload-main">
                      <Form.Label>Image</Form.Label>
                      <div className="input-image-show">
                        <Form.Control type="file" placeholder="Upload Image" />

                        <p>Upload Image</p>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={8}>
                    <Form.Group className="mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control type="text" placeholder="Type Here" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Price of the service</Form.Label>
                      <Form.Control type="text" placeholder="Type Here" />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="customer-form-new mb-4">
              <div className="customer-form-inner">
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="text-uppercase">
                          Add Sub-Categories
                        </Form.Label>
                        <button type="button" className="add-btn-category">
                          ADD
                        </button>
                      </div>
                      <div className="customer-form-inner-new">
                        <Row>
                          <Col lg={4}>
                            <Form.Group className=" mb-3 image-upload-main">
                              <Form.Label>Image</Form.Label>
                              <div className="input-image-show">
                                <Form.Control
                                  type="file"
                                  placeholder="Upload Image"
                                />

                                <p>Upload Image</p>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={8}>
                            <Form.Group className="mb-3">
                              <Form.Label>Add Categories</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Add Categories....."
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Add Price</Form.Label>
                              <Form.Control type="number" placeholder="Price" />
                            </Form.Group>
                          </Col>
                          <Form.Group className="">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Type Here"
                            />
                          </Form.Group>
                        </Row>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Choose property</Form.Label>

                        <Form.Select aria-label="Default select example">
                          <option>Danny</option>
                          <option value="1">Kevin</option>
                          <option value="2">Sam</option>
                          <option value="3">Sam</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12} className="d-flex justify-content-center mt-3">
                    <button type="button" className="add-btn">
                      Submit
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
