import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function CustomerManagement() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Customer Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="#">DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement">
                  ALL <span>20</span>
                </Link>
              </li>
              <li>
                <Link to="/CustomerManagement" className="active-tab">
                  Customer <span> 18</span>
                </Link>
              </li>
              <li>
                <Link to="/VendorManagement">
                  Vendor <span>2</span>
                </Link>
              </li>
              <li>
                <Link to="/ManagePropertyManagers">
                  Property Managers <span>2</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 10 of 20 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number </th>
                <th>Location</th>
                <th>Profile Image</th>
                <th>Account Status</th>
              </tr>
            </thead>
            <tbody>
             
              <tr>
                <td>1</td>
                <td>
                  <Link to="/CustomerProfile">John</Link>
                </td>
                <td>Smith</td>
                <td>John@gmail.com</td>
                <td>1234567891</td>
                <td>New york</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Temporary Banned</td>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  <Link to="/CustomerProfile">John</Link>
                </td>
                <td>Smith</td>
                <td>John@gmail.com</td>
                <td>1234567891</td>
                <td>New york</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Temporary Banned</td>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  <Link to="/CustomerProfile">John</Link>
                </td>
                <td>Smith</td>
                <td>John@gmail.com</td>
                <td>1234567891</td>
                <td>New york</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Temporary Banned</td>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  <Link to="/CustomerProfile">John</Link>
                </td>
                <td>Smith</td>
                <td>John@gmail.com</td>
                <td>1234567891</td>
                <td>New york</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Temporary Banned</td>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  <Link to="/CustomerProfile">John</Link>
                </td>
                <td>Smith</td>
                <td>John@gmail.com</td>
                <td>1234567891</td>
                <td>New york</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Temporary Banned</td>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  <Link to="/CustomerProfile">John</Link>
                </td>
                <td>Smith</td>
                <td>John@gmail.com</td>
                <td>1234567891</td>
                <td>New york</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Temporary Banned</td>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  <Link to="/CustomerProfile">John</Link>
                </td>
                <td>Smith</td>
                <td>John@gmail.com</td>
                <td>1234567891</td>
                <td>New york</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Temporary Banned</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div>
    </Layout>
  );
}
