import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { jobProfile } from "../Redux/actions/adminactions";
import { useDispatch } from "react-redux";

export default function Viewjob() {
  const location = useLocation();
  console.log(location?.state?.id);
  const dispatch = useDispatch();

  const [JobDetails, setJobDetails] = useState();

  // const [userid, setUserid] = useState({
  //   userId: location?.state?.id,
  // });

  // useEffect(() => {
  //   setUserid((old) => ({ ...old, userId: location?.state?.id }));
  // }, [location?.state?.id]);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let data = await dispatch(jobProfile(location?.state?.id));
        setJobDetails(data?.payload);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, [location?.state?.id]);

  console.log(JobDetails);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Job</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/listingmanagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2 className="text-uppercase">Jobs Card</h2>
                  <hr />
                  <Row>
                    <Col lg={6}>
                      <div className="member-info-inner">
                        <h2>Company Name</h2>
                        <div className="member-inner">
                          <p>{JobDetails?.companyName}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="member-info-inner">
                        <h2>Title</h2>
                        <div className="member-inner">
                          <p>{JobDetails?.job?.jobTitle}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Description</h2>
                        <div className="member-inner">
                          <p>{JobDetails?.job?.description}</p>
                        </div>
                      </div>
                    </Col>{" "}
                    <Col lg={6} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Job Type</h2>
                        <div className="member-inner">
                          <p>{JobDetails?.job?.remoteOrInPerson}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Job Role</h2>
                        <div className="member-inner">
                          <p>{JobDetails?.job?.repsNeeded}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Average Offer Price</h2>
                        <div className="member-inner">
                          <p>{JobDetails?.job?.offerPrice}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-4">
                      <div className="member-info-inner ">
                        <h2>Days Availablity</h2>
                        <div className="member-inner d-flex">
                          {JobDetails?.job?.workDays?.map((x,index) => {
                            let data;
                            if(x?.value?.length>4){
                            data = x?.value.slice(0, 3);

                            }else{
                             data= x?.value
                            }
                            
                            return <p>{data}{JobDetails?.job?.workDays[JobDetails?.job?.workDays?.length - 1] !== x ? "," : ""} </p>;
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Choose Industry</h2>
                        <div className="member-inner">
                          <p>{JobDetails?.job?.industryCategory}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-4">
                      <div className="member-info-inner">
                        <h2>What's The Monthly OTE</h2>
                        <div className="member-inner">
                          <p>{JobDetails?.job?.monthlyOTE}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>STATUS</h2>
                <p>Completed</p>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </Layout>
  );
}
