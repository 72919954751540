import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../Services/Adminapi";
import { toast } from "react-toastify";

const getUsers = createAsyncThunk("getUsers", async (userMgmtParams) => {
  const { data } = await adminApi.get(
    `/getUsers?page=${userMgmtParams.page}&limit=${userMgmtParams.limit}&search=${userMgmtParams.search}`
  );
  return data;
});

const getUserlistByRole = createAsyncThunk(
  "getUserlistByRole",
  async (details) => {
    const { data } = await adminApi.get(
      `/getUsersList?page=${details.page}&limit=${details.limit}&role=${details.role}&search=${details.search}&banStatus=${details.banStatus}`
    );
    return data;
  }
);
const getAllData = createAsyncThunk("getUserlistByRole", async (details) => {
  const { data } = await adminApi.get(
    `/getUsersList?page=${details.page}&limit=${details.limit}&role=${details.role}&search=${details.search}`
  );
  return data;
});

const dashboardData = createAsyncThunk("dashboardData", async () => {
  const { data } = await adminApi.get("/getUsers");
  return data;
});

const logoutUser = createAsyncThunk("logoutUser", async () => {
  const { data } = await adminApi.put("/logout");

  return data;
});

const ChangeUserPassword = createAsyncThunk(
  "changePassword",
  async (details) => {
    const { data } = await adminApi.post("/changePassAdmin", details);

    return data;
  }
);

const userProfile = createAsyncThunk("userProfile", async (details) => {
  const { data } = await adminApi.get(`/getUserProfile?id=${details}`);

  return data;
});

const jobProfile = createAsyncThunk("jobProfile", async (details) => {
  const { data } = await adminApi.get(`/getJobById?jobId=${details}`);

  return data;
});

const deleteUser = createAsyncThunk("deleteUser", async (details) => {
  const { data } = await adminApi.post("/deleteUserProfile", details);

  return data;
});

const deleteJob = createAsyncThunk("deleteJob", async (details) => {
  const { data } = await adminApi.delete(`/deleteJobById?jobId=${details}`);

  return data;
});

const temporaryBanned = createAsyncThunk("temporaryBanned", async (details) => {
  const { data } = await adminApi.post("/tempBanOrUnbanAccount", details);

  return data;
});

const getJobs = createAsyncThunk("getJobs", async (details) => {
  let url = `/getJobs?page=${details.page}&limit=${details.limit}&search=${details.search}`;
  
  // If details.status is present, add it to the URL
  if (details.status) {
    url += `&status=${details.status}`;
  }

  const { data } = await adminApi.get(url);
  return data;
});
const getAllJobs = createAsyncThunk(`getAllJobs`, async () => {
  const { data } = await adminApi.get(`/getJobs`);
  return data;
});

const userActivation = createAsyncThunk("userActivation", async (details) => {
  const { data } = await adminApi.put("/activateDeactivateUser", details);
  return data;
});
const addjob = createAsyncThunk("addjob", async (details) => {
  const { data } = await adminApi.post("/addJob", details);
  return data;
});
const verification = createAsyncThunk("verification", async (details) => {
  const { data } = await adminApi.get(
    `/getBadgeVerificationList?page=${details.page}&limit=${details.limit}&search=${details.search}&type=${details.userType}`
  );
  return data;
});
const updateVerificationStatus = createAsyncThunk(
  "updateVerificationStatus",
  async (details) => {
    const { data } = await adminApi.put(`/badgeVerification`, details);
    return data;
  }
);
const addnotification = createAsyncThunk("addNotification", async (details) => {
  const { data } = await adminApi.post(`/addNotification`, details);
  return data;
});
const allnotification = createAsyncThunk("allNotification", async (details) => {
  const { data } = await adminApi.get(
    `/getAllNotifications?page=${details.page}&search=${details.search}&limit=${details.limit}`
  );
  return data;
});
const deletenotification = createAsyncThunk(
  "deletenotification",
  async (details) => {
    const { data } = await adminApi.post(
      `/deleteNotification?id=${details.id}`
    );
    return data;
  }
);
const manageChatUsers = createAsyncThunk("manageChatUsers", async () => {
  const { data } = await adminApi.get(`/getUsersFullName`);
  return data;
});
const chatbtwnuser = createAsyncThunk("chatbtwnuser", async (details) => {
  const { data } = await adminApi.post("/getUsersFullNameChats", details);
  return data;
});
const getAllSupports = createAsyncThunk(
  "getUserlistByRole",
  async (details) => {
    const { data } = await adminApi.get(
      `/getSupportChats?page=${details.page}&limit=${details.limit}&search=${details.search}`
    );
    return data;
  }
);
const getSupportById = createAsyncThunk("getSupportById", async (details) => {
  const { data } = await adminApi.get(`getSupportById?id=${details}`);

  return data;
});
const changeSupportStatus = createAsyncThunk(
  "changeSupportStatus",
  async (details) => {
    const { data } = await adminApi.post("/changeSupportStatus", details);

    return data;
  }
);
const createNotes = createAsyncThunk("createNotes", async (details) => {
  const { data } = await adminApi.post("/createSupport", details);

  return data;
});
const getUnVerifiedUserList = createAsyncThunk("verification", async (details) => {
  const { data } = await adminApi.get(
    `/getUnVerifiedUserList?page=${details.page}&limit=${details.limit}&search=${details.search}&role=${details.userType}`
  );
  return data;
});
const updateAccountVerificationStatus = createAsyncThunk(
  "updateAccountVerificationStatus",
  async (details) => {
    const { data } = await adminApi.post(`/verifyUser`, details);
    return data;
  }
);

export {
  getUsers,
  logoutUser,
  ChangeUserPassword,
  userProfile,
  deleteUser,
  temporaryBanned,
  getJobs,
  dashboardData,
  getUserlistByRole,
  userActivation,
  getAllData,
  jobProfile,
  deleteJob,
  getAllJobs,
  addjob,
  verification,
  updateVerificationStatus,
  addnotification,
  allnotification,
  deletenotification,
  manageChatUsers,
  chatbtwnuser,
  getAllSupports,
  getSupportById,
  changeSupportStatus,
  createNotes,
  getUnVerifiedUserList,
  updateAccountVerificationStatus
};
