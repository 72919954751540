import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  updateVerificationStatus,
  userActivation,
  userProfile,
} from "../Redux/actions/adminactions";
import { toast } from "react-toastify";
import logo from "../Assets/Images/Logo.svg";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ActivationStatus } from "../Redux/reducers/userProfileSlice";

export default function UserProfile() {
  // const statusFromStore = useSelector((state) => state.Activation.status);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [removeVer,setRemoveVer] = useState(false)
  const [addVer,setAddVer] = useState(false)
  const [path, setPath] = useState();
  console.log(location?.state?.path, "kbutr");

  const [flag, setFlag] = useState(true);
  const [ProfileData, setProfileData] = useState();

  const [activationStatus, setActivationStatus] = useState(false);

  // const [ActivationMessage,setActivationMessage] = useState("")

  const [userid, setUserid] = useState({
    userId: location?.state?.id,
  });

  useEffect(() => {
    setUserid((old) => ({ ...old, userId: location?.state?.id }));
  }, [location?.state?.id]);

  useEffect(() => {
    setPath(location?.state?.path);
  }, [location?.state?.path]);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let data = await dispatch(userProfile(location?.state?.id));
        setActivationStatus(data?.payload?.status == 0 ? true : false);
        setProfileData(data?.payload);
        // setActivationStatus(data?.payload?.status)
      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, [location?.state?.id, flag]);

  const handledelete = async () => {
    try {
      let data = await dispatch(deleteUser(userid));
      console.log(data);
      if (data?.payload?.message === "User deleted.") {
        toast.success(data?.payload?.message);
        navigate("/UserManagement");
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error("Error deleting user");
    }
  };

  const handleActivation = async () => {
    // dispatch(ActivationStatus(activationStatus));
    try {
      let data = await dispatch(
        userActivation({
          id: location?.state?.id,
          // status: e.target.checked === true ? 0 : 1,
          status: activationStatus,
        })
      );

      if (data?.payload?.message === "Activation status updated") {
        toast.success("Account Updated Successfully");
        // setFlag(!activationStatusFlag)
        setShowd(false);
        setFlag(!flag);
        // setActivationStatus(data?.payload)
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      toast.error("Error deactivating user");
    }
  };
  
  const handleReject = async () => {
   
    try {
      let data = await dispatch(
        updateVerificationStatus({ id: ProfileData?.id, badgeVerificationStatus: "1" })
      );
      console.log(data);
      if (
        data?.payload?.message === "Badge verification updated successfully."
      ) {
        toast.success(data?.payload?.message);
        setRemoveVer(false)
        setFlag(!flag);
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccept = async () => {
    
    try {
      let data = await dispatch(
        updateVerificationStatus({ id: ProfileData?.id, badgeVerificationStatus: "0" })
        
      );
      if (
        data?.payload?.message === "Badge verification updated successfully."
      ) {
        toast.success(data?.payload?.message);
        setFlag(!flag);
        setAddVer(false)
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(()=>{

  // },[ProfileData])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showd, setShowd] = useState(false);

  const handleClosed = () => setShowd(false);

  const handleShowd = (e) => {
    setActivationStatus(e.target.checked == true ? "0" : "1");
    setShowd(true);
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>
              {ProfileData?.role === "seller"
                ? "Business Profile"
                : "User Profile"}
            </h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                {/* <Link to="/UserManagement"> */}
                <Link to={path}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12} className="d-flex justify-content-between">
                      <Figure className="d-flex">
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src={
                            ProfileData?.profileImage
                              ? ProfileData?.profileImage
                              : logo
                          }
                        />
                        <Figure.Caption>
                          <h2>
                            {ProfileData?.fullName}{" "}
                            {ProfileData?.badgeVerificationStatus === 0 ?
                            <img
                              className="verify-tick"
                              src={
                                require("../Assets/Images/tickmark.svg").default
                              }
                            /> :null}
                          </h2>

                          <p>
                            {/* <span>Email - </span> */}
                            {/* 22 Shelton Street, London SW6 */}
                            {/* {userData ? (userData?.address , userData?.city , userData?.state , userData.postalCode) : null} */}
                            {ProfileData?.companyName
                              ? ProfileData?.companyName
                              : null}
                            {ProfileData?.country
                              ? ProfileData.country + " ,"
                              : null}{" "}
                            {ProfileData?.city
                              ? ProfileData?.city + " ,"
                              : null}
                            {ProfileData?.state ? ProfileData?.state : null}{" "}
                          </p>
                          <p className="mb-3">
                            {/* <span>Phone - </span>  */}
                            {/* United Kingdom */}
                            <p>{ProfileData?.address}</p>
                            <p>
                              {ProfileData?.postalCode
                                ? ProfileData?.postalCode
                                : null}
                            </p>
                          </p>

                          <p>{ProfileData?.email}</p>
                          <p>{ProfileData?.phone}</p>
                        </Figure.Caption>
                      </Figure>
                      <div class="dlt-ac-btn mt-4">
                      {ProfileData?.badgeVerificationStatus === 0 ?
                      <button
                              type="button"
                              onClick={()=>setRemoveVer(true)}
                              // onClick={handledelete}
                              //  href="/Support"
                            >
                              Remove Verification
                      </button>
                      :
                      <button
                              type="button"
                              onClick={()=>setAddVer(true)}
                              // onClick={handledelete}
                              //  href="/Support"
                            >
                              Add Verification
                      </button>}

                      </div>
                    </Col>
                    <Col lg={12}>
                      <hr />
                      <Row>
                        <Col lg={6}>
                          <div className="member-info-inner">
                            <h4>{ProfileData?.niche }</h4>
                            <div className="member-inner">
                              <p>{ProfileData?.saleRole}</p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}></Col>
                        <Col lg={12} className="mt-4">
                          <div className="member-info-inner">
                            <h4>Available Day</h4>
                            <div className="member-inner d-flex gap-1">
                              {ProfileData?.workDays?.length > 0
                                ? ProfileData.workDays.map((x) => (
                                    <p key={x._id}>
                                      {x.value}
                                      {ProfileData.workDays[
                                        ProfileData.workDays.length - 1
                                      ] == x
                                        ? ""
                                        : ","}
                                    </p>
                                  ))
                                : ProfileData?.daysAvailable?.map((x, i) => (
                                    <p key={x._id}>
                                      {x.value}
                                      {ProfileData.daysAvailable[
                                        ProfileData.daysAvailable.length - 1
                                      ] == x
                                        ? ""
                                        : ","}
                                    </p>
                                  ))}
                            </div>
                          </div>
                        </Col>
                        {ProfileData?.timezone ? (
                          <Col lg={4} className="mt-4">
                            <div className="member-info-inner">
                              <h4>Time Zone</h4>
                              <div className="member-inner">
                                <p>{ProfileData?.timezone}</p>
                              </div>
                            </div>
                          </Col>
                        ) : null}
                        <Col lg={4} className="mt-4">
                          <div className="member-info-inner">
                            <h4>
                              {ProfileData?.role === "seller"
                                ? "Average Offer Price"
                                : "Last Offer Revenue"}
                            </h4>
                            <div className="member-inner">
                              <p>
                                {ProfileData?.revenue
                                  ? ProfileData?.revenue
                                  : ProfileData?.offerPrice}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} className="mt-4">
                          <div className="member-info-inner">
                            {ProfileData?.hiring ? (
                              <>
                                {" "}
                                <h4>HIRING</h4>
                                <div className="member-inner">
                                  <p>{ProfileData?.hiring}</p>
                                </div>
                              </>
                            ) : (
                              <>
                                <h4>SALES TRAINING</h4>
                                <div className="member-inner">
                                  <p>{ProfileData?.salesTraining}</p>
                                </div>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </Col>
                  </Row>
                </div>
                {ProfileData?.role !== "seller" ? (
                  <div className="user-profile-main mt-4">
                    {ProfileData?.role !== "seller" ? (
                      <Row>
                        <Col lg={12}>
                          <div className="member-info-inner mb-4">
                            <h4>Offers</h4>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                    {ProfileData?.role !== "seller" ? (
                      <table className="user-table">
                        <tr>
                          <th>Date</th>
                          <th>Niche/Offer</th>
                          <th>
                            Total Revenue Generated
                            <br /> in Offer
                          </th>
                          <th>
                            Average Deal Size
                            <br /> Price Sold
                          </th>
                        </tr>
                        <tr>
                          <td>
                            {ProfileData?.offersWorked?.map((x) => {
                              let cutDate = x?.date?.slice(0, 10);

                              return <p>{cutDate}</p>;
                            })}
                          </td>
                          <td>
                            {ProfileData?.offersWorked?.map((x) => (
                              <p>{x?.niche}</p>
                            ))}
                          </td>
                          <td>
                            {" "}
                            {ProfileData?.offersWorked?.map((x) => (
                              <p>{x?.revenue}</p>
                            ))}
                          </td>
                          <td>
                            {ProfileData?.offersWorked?.map((x) => (
                              <p>{x?.averageDeal}</p>
                            ))}
                          </td>
                        </tr>
                      </table>
                    ) : null}
                  </div>
                ) : null}
              </Col>

              <Col lg={4}>
                <Row>
                  <Col lg={12} className="mb-3">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <Row>
                        <Col lg={12}>
                          <div className="property-main mt-0">
                            <h2 className="property">Intro Video</h2>
                            <div className="video-proper mt-2">
                              {ProfileData?.introUrl && (
                                <video width="100%" height="240" controls>
                                  <source
                                    src={ProfileData?.introUrl}
                                    type="video/mp4"
                                  />
                                </video>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {ProfileData?.callRecording ? (
                    <Col lg={12} className="mb-3">
                      <div className="user-profile-main px-3 py-4 h-100">
                        <Row>
                          <Col lg={12}>
                            <div className="property-main mt-0">
                              <h2 className="property">Sales video</h2>
                              <div className="video-proper mt-2">
                                <video width="100%" height="240" controls>
                                  <source
                                    src={ProfileData?.callRecording}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ) : null}
                  <Col lg={12}>
                    <div className="user-profile-main">
                      <Row>
                        <Col lg={12}>
                          <div className="property-main mt-0">
                            <h2 className="property">ACTIVITY</h2>
                            <div className="product-action-inner">
                              <h3>[Admin Name] add Verification Badge</h3>
                              <p>
                                01/01/2023 <span>13:32</span>
                              </p>
                            </div>
                            <div className="product-action-inner">
                              <h3>[Admin Name] remove Verification Badge</h3>
                              <p>
                                01/01/2023 <span>13:32</span>
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="mt-5 mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-4">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                          <div class="dlt-ac-btn mt-4">
                            <button
                              type="button"
                              onClick={handleShow}
                              // onClick={handledelete}
                              //  href="/Support"
                            >
                              Delete Account
                            </button>
                          </div>
                        </Figure.Caption>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="mt-5 mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Deactivate Account</h2>
                        <Figure.Caption className="m-0 mt-4">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24hours.
                          </p>
                          <div class="form-check form-switch mt-3">
                            <input
                              onClick={handleShowd}
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              name="activationStatus"
                              checked={activationStatus}
                              // onChange={handleActivation}
                            />
                          </div>
                        </Figure.Caption>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} className="delete-popup">
        <Modal.Body>
          <h2>Are You Sure You Want To delete This Account</h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="secondary" onClick={handledelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showd} onHide={handleClosed} className="delete-popup">
        <Modal.Body>
          <h2>
            {activationStatus == true
              ? "Are You Sure You Want To Activate This Account"
              : "Are You Sure You Want To Deactivate This Account"}
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosed}>
            No
          </Button>
          <Button variant="secondary" onClick={handleActivation}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={removeVer}  className="delete-popup">
        <Modal.Body>
          <h2>
              Are You Sure You Want to remove verification of this Account
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setRemoveVer(false)}>
            No
          </Button>
          <Button variant="secondary" onClick={handleReject} >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={addVer} className="delete-popup">
        <Modal.Body>
          <h2>
              Are You Sure You Want to add verification to this Account
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>addVer(false)}>
            No
          </Button>
          <Button variant="secondary" onClick={handleAccept} >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
