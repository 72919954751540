import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addnotification,
  allnotification,
  deletenotification,
} from "../Redux/actions/adminactions";
import { toast } from "react-toastify";
import Pagination from "../Components/Pagination";

export default function Notification() {
  let dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [DeletionId, setDeletionId] = useState("");
  const [notificationParams, setNotificationParams] = useState({
    page:"1",
    search:"",
    limit: "10",
  });
  const [allNotifications, setAllNotifications] = useState(null);
  const [flag, setFlag] = useState(true);
  const [pagination, setpagination] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await dispatch(allnotification(notificationParams));

        setAllNotifications(data?.payload);

        for (
          let i = 1;
          i <=
          Math.ceil(data?.payload?.totalNotifications / notificationParams.limit);
          i++
        ) {
          setpagination(i);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [notificationParams, flag]);
  
  const handlePages = (page) => {
    // dispatch(setPageNumber(page));

    setNotificationParams((old) => ({ ...old, page: page }));
  };
  const handleShow = (id) => {
    console.log(id)
    setDeletionId(id);

    setShow(true);
  };

  const handledelete = async () => {
    

    try {
      let data = await dispatch(deletenotification({ id: DeletionId }));
      console.log(data);
      if (data?.payload?.status === 200) {
        toast.success(data?.payload?.message);
        setFlag(!flag);
        setShow(false)
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlelimit = (e) => {
    setNotificationParams((old) => ({
      ...old,
      limit: e.target.value ? e.target.value : "10",
    }));
  };

  const handlesearch = (e) => {
    setNotificationParams((old) => ({ ...old, search: e.target.value }));
  };

  console.log(allNotifications)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Notifications</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" onChange={handlesearch} name="search" value={notificationParams.search} placeholder="Keyword Search.." />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={4}
                md={3}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/AddNotification">ADD NOTIFICATION</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results"> Showing{" "}
                    {1 + (notificationParams?.page - 1) * notificationParams?.limit} -{" "}
                    {allNotifications?.notifications?.length +
                      (notificationParams?.page - 1) * notificationParams.limit}{" "}
                    of {allNotifications?.totalNotifications} results
                    </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlelimit}
                  value={notificationParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>TITLE</th>
                <th>DESCRIPTION</th>
                <th>TO</th>
                <th>ACTION </th>
              </tr>
            </thead>
            <tbody>
              {allNotifications?.notifications?.map((item, index) => {
                const serialNumber =
                  (notificationParams?.page - 1) * notificationParams?.limit +
                  index +
                  1;

                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{item?.title}</td>
                    <td>{item?.desc}</td>
                    <td>
                      {item?.to === 0
                        ? "All Users"
                        : item?.to === 1
                        ? "Sales Representatives"
                        : "Business Owner"}
                    </td>
                    <td>
                      <Button
                        className="account-btn"
                        onClick={() => handleShow(item?._id)}
                      >
                        DELETE
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}

              {/* <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Business Owners</td>
                <td>
                  <Button className="account-btn">DELETE</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Sales Representative</td>
                <td>
                  <Button className="account-btn">DELETE</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Business Owners</td>
                <td>
                  <Button className="account-btn">DELETE</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>All males</td>
                <td>
                  <Button className="account-btn">DELETE</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Sales Representative</td>
                <td>
                  <Button className="account-btn">DELETE</Button>{" "}
                </td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={show} className="delete-popup">
            <Modal.Body>
              <h2>Are You Sure You Want To delete This Notification</h2>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShow(false)}>
                No
              </Button>
              <Button variant="secondary"
               onClick={handledelete}
               >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

      <Pagination
        // totalpagination={totalpagination}
        pagination={pagination}
        paginationevent={handlePages}
      />
    </Layout>
  );
}
