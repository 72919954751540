import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Active 50%", "Inactive 50%"],

  datasets: [
    {
      label: "# of Users",
      data: [100, 100],
      backgroundColor: ["#8054FF", "#FF9898"],

      borderWidth: 1,
    },
  ],
};

export default function ChartJobs() {
  return <Doughnut className="gender-chart" data={data} />;
}
