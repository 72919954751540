import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteJob,
  getAllJobs,
  getJobs,
  jobProfile,
} from "../Redux/actions/adminactions";
import Pagination from "../Components/Pagination";
import { setPageNumber } from "../Redux/reducers/userMgmtSlice";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

export default function ListingManagement() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState(null);
  const [pagination, setpagination] = useState(null);
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(true);
  const [data, setData] = useState(null);
  const [DeletionId, setDeletionId] = useState("");
  const [jobMgmtParams, setJobMgmtParams] = useState({
    page:  "1",
    search: "",
    limit: "10",
    
  });

  // const data = useSelector((state) => state.jobs.alljobs);
  // console.log(data,'dlice');

  console.log(jobsData);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let alljobs = await dispatch(getAllJobs());

        setData(alljobs);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, []);

  const handleShow = (id) => {
    setDeletionId(id);

    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let jobsData = await dispatch(getJobs(jobMgmtParams));
        setJobsData(jobsData?.payload?.jobs);
      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, [jobMgmtParams, flag]);

  const handleclick = (id) => {
    console.log(id);
    navigate("/viewjob", {
      state: {
        id: id,
      },
    });
  };
  console.log(DeletionId);

  const handledelete = async () => {
    try {
      let data = await dispatch(deleteJob(DeletionId));
      console.log(data);
      if (data?.payload?.message === "Job deleted.") {
        toast.success(data?.payload?.message);
        setShow(false);
        setFlag(!flag);
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log("Error deleting user");
    }
  };

  const handlePages = (page) => {
    dispatch(setPageNumber(page));

    setJobMgmtParams((old) => ({ ...old, page: page }));
  };

  useEffect(() => {
    for (
      let i = 1;
      i <= Math.ceil((jobMgmtParams?.search ? jobsData?.length : data?.payload?.jobs?.length) / jobMgmtParams.limit);
      i++
    ) {
      setpagination(i);
    }
  }, [data, jobMgmtParams]);

  const handlesearch = (e) => {
    setJobMgmtParams((old) => ({ ...old, search: e.target.value }));
  };
  const handlelimit = (e) => {
    setJobMgmtParams((old) => ({
      ...old,
      limit: e.target.value ? e.target.value : "10",
    }));
  };

  return (
    
      
        <Layout>
          <div className="right-top">
            <div className="heading-top">
              <h2>Posted Jobs</h2>
            </div>
            <hr />
            <Container fluid>
              <div className="cmn-top-fields">
                <Row>
                  <Col xxl={3} xl={3} lg={4} md={4}>
                    <Form.Group>
                      <Form.Control
                        type="search"
                        onChange={handlesearch}
                        name="search"
                        value={jobMgmtParams.search}
                        placeholder="Keyword Search.."
                      />
                    </Form.Group>
                  </Col>
                 
                  <Col
                    xxl={9}
                    xl={9}
                    lg={8}
                    md={8}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div className="cmn-btn">
                      <Link to="/add-job">ADD Post</Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            
            <Container fluid>
              <div className="product-tab">
                <ul>
                  <li>
                    <Link to="#" className="active-tab">
                      All
                    </Link>
                  </li>
                  <li>
                    <Link to="/listingmanagementactive"> Active</Link>
                  </li>
                  <li>
                    <Link to="/listingmanagementexpired">Expired</Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
          <Container fluid>
            <div className="results-sec">
              <Row>
                <Col lg={6} className="d-flex align-items-center">
                  <div className="show-results">
                    {/* Showing 1 - 10 of {data?.payload?.jobs?.length} results */}
                    Showing{" "}
                    {1 + (jobMgmtParams?.page - 1) * jobMgmtParams?.limit} -{" "}
                    {jobsData?.length +
                      (jobMgmtParams?.page - 1) * jobMgmtParams.limit}{" "}
                    of {data?.payload?.jobs?.length}  results
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="select-result d-flex align-items-center justify-content-end">
                    <span>Results per page</span>{" "}
                    <Form.Select
                      onChange={handlelimit}
                      value={jobMgmtParams.limit}
                      aria-label="Default select example"
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="15">25</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>

          <Container fluid className="px-0">
            <div class="table-responsive main">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Company Name</th>
                    <th>Job post Title</th>
                    <th>Description</th>
                    <th>Remote/In-persons </th>
                    <th>Setter/Close</th>

                    <th>Days Availability requirement</th>

                    <th>Monthly OTE</th>
                    <th>Job Status</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {jobsData?.map((item, index) => {
                    const serialNumber =
                      (jobMgmtParams?.page - 1) * jobMgmtParams?.limit +
                      index +
                      1;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleclick(item.id)}
                        >
                          {item?.companyName ||"N/A"}
                        </td>
                        <td>{item?.jobTitle}</td>
                        <td>
                          <div className="content">{item.description}</div>
                        </td>
                        <td>{item?.remoteOrInPerson}</td>
                        <td>{item?.repsNeeded}</td>

                        <td className="days-show">
                          {item?.workDays?.map((x) => {
                            let data;
                            if (x?.value?.length > 4) {
                              data = x?.value?.slice(0, 3);
                            } else {
                              data = x?.value;
                            }

                            return (
                              <p>
                                {data}
                                {item?.workDays[item?.workDays?.length - 1] !==
                                x
                                  ? ","
                                  : ""}
                              </p>
                            );
                          })}
                        </td>

                        <td>{item.monthlyOTE}</td>
                        <td>Active</td>
                        <td className="order-btn">
                          <button
                            type="button"
                            onClick={() => handleShow(item?.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              
            </div>
          </Container>
          <Modal show={show} onHide={handleClose} className="delete-popup">
            <Modal.Body>
              <h2>Are You Sure You Want To delete This Job</h2>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="secondary"
               onClick={handledelete}
               >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <Pagination pagination={pagination} paginationevent={handlePages} />
        </Layout>
     
   
  );
}
