import { createSlice } from "@reduxjs/toolkit";
import { dashboardData } from "../actions/adminactions";

const initialState={
    dashboardData:null
}


export const DashboardSlice=createSlice({
    name:"DashboardSlice",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(dashboardData.fulfilled,(state,action)=>{
           state.dashboardData=action.payload

        })
    }

})

export default DashboardSlice.reducer