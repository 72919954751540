import { createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { getUsers } from "../actions/adminactions";

const initialState = {
  allusers: null,
};

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.allusers = action.payload;
      } else {
        toast.error(action.payload?.message);
      }
    });
  },
});
export default userMgmtSlice.reducer;

export const userTypeSlice = createSlice({
  name: "userTypeSlice",
  initialState: {
    SelectedUserType: "",
  },
  reducers: {
    setUserType: (state, action) => {
      state.SelectedUserType = action.payload;
    },
  },
});

export const { setUserType } = userTypeSlice.actions;

export const pageNumberSlice = createSlice({
  name: "userTypeSlice",
  initialState: {
    PageNumber: "",
    Path:""
  },
  reducers: {
    setPageNumber: (state, action) => {
      state.PageNumber = action.payload;
    },
    alluserpath: (state, action) => {
      state.Path = action.payload;
    },
  },
});

export const { setPageNumber,alluserpath } = pageNumberSlice.actions;

export const BannedpageNumberSlice = createSlice({
  name: "userTypeSlice",
  initialState: {
    PageNumber: "",
    Path:""
  },
  reducers: {
    setBannedPageNumber: (state, action) => {
      state.PageNumber = action.payload;
    },
    bannedPath: (state, action) => {
      console.log(action)
      state.Path = action.payload;
    },
  },
});

export const { setBannedPageNumber,bannedPath } = BannedpageNumberSlice.actions;

export const ActivepageNumberSlice = createSlice({
  name: "userTypeSlice",
  initialState: {
    PageNumber: "",
    Path:""
  },
  reducers: {
    activepageNumber: (state, action) => {
      console.log(action.payload);
      state.PageNumber = action.payload;
    },
    openactivepath: (state, action) => {
      console.log(action.payload);
      state.Path = action.payload;
    },
  },
});

export const { activepageNumber ,openactivepath} = ActivepageNumberSlice.actions;

// export const BannedPathSlice = createSlice({
//   name: "userTypeSlice",
//   initialState: {
//     Path: "",
//   },
//   reducers: {
//     bannedPath: (state, action) => {
//       state.Path = action.payload;
//     },
//   },
// });

// export const { bannedPath } = BannedPathSlice.actions;

export const ActivePathSlice = createSlice({
  name: "userTypeSlice",
  initialState: {
    Path: "",
  },
  reducers: {
    activePath: (state, action) => {
      state.Path = action.payload;
    },
  },
});

export const { activePath } = ActivePathSlice.actions;
