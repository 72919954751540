import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { setUserType } from "../../../Redux/reducers/userMgmtSlice";
import { getAllData } from "../../../Redux/actions/adminactions";


ChartJS.register(ArcElement, Tooltip, Legend);



export default function ChartSubscription() {

  const [seller,setSeller] = useState("")
  const [reps,setReps] = useState("")
 
  

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(setUserType("seller"));
    const fetchData = async () => {
      try {
        let alluser = await dispatch(
          getAllData({ page:"",limit:"",search:"", role: "seller"})
        );
        setSeller(alluser?.payload?.totalUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // dispatch(setUserType("reps"));
    const fetchData = async () => {
      try {
        let alluser = await dispatch(
          getAllData({ page:"",limit:"",search:"", role: "rep"})
        );
        // console.log(alluser)
        setReps(alluser?.payload?.totalUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

   const data = {
    labels: ["Subscribed users", "Unsubscribed user"],
    datasets: [
      {
        label: "",
        data: [0, 0],
        backgroundColor: ["#8054FF", "#FF9898"],
  
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut className="gender-chart" data={data} />;
}
