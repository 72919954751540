import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../Redux/actions/adminauth";
import { toast } from "react-toastify";
import logo from "../Assets/Images/Logo.svg";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  
  const [showSplash, setShowSplash] = useState(false);

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    emailerr: "",
    passworderr: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    validation();
  };

  const handlechange = (e) => {
    setUserData((old) => ({ ...old, [e.target.name]: e.target.value }));
    setError((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };

  const validation = async () => {
    let isvalid = true;
    let err = {
      emailerr: "",
      passworderr: "",
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userData.email) {
      err.emailerr = "Please Enter Email";
      isvalid = false;
    } else if (!emailRegex.test(userData.email)) {
      err.emailerr = "Please Enter Valid Email";
      isvalid = false;
    }
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!userData.password) {
      err.passworderr = "Please Enter Password";
      isvalid = false;
    }
    // else if (!passwordRegex.test(userData.password)) {
    //   err.passworderr = "Please Enter Valid Password";
    //   isvalid = false;
    // }

    if (!isvalid) {
      setError(err);
    }
    if (isvalid) {
      try {
        const data = await dispatch(login(userData));
        console.log(data);

        if (data?.payload?.message === "Admin login successfully.") {
          toast.success(data?.payload?.message);
         

          setShowSplash(true);

          
          // navigate("/Dashboard");
          setTimeout(() => {
            setShowSplash(false);
            // navigate("/dashboard");
            window.location.href="/Dashboard"
          }, 2000);
          // navigate("/dashboard");
        }
        // else if(data?.payload?.message === undefined){
        //   toast.error("Server not responding")
        // }
        else {
          toast.error(data?.payload?.message || "Invalid Credentials");
        }
      } catch (error) {
        toast.error("Error login user");
      }
    }
  };

  return (
    <>
    {showSplash ? (
      <div className="splash-container">
        <div className="splash-content">
        <img src={logo}/></div>
      </div>
    ) : (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Log in</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Type Here"
                        name="email"
                        value={userData.email}
                        onChange={handlechange}
                      />
                      <Form.Label className="text-danger">
                        {error.emailerr}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Type Here"
                        name="password"
                        value={userData.password}
                        onChange={handlechange}
                      />
                      <Form.Label className="text-danger">
                        {error.passworderr}
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <Link
                    onClick={handleSubmit}
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Log In
                  </Link>
                  <Link to="/Forgot-Password" className="forgot">
                    Forgot Password?
                  </Link>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    )}
    </>
  );
}
