import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";

import Support from "./pages/Support";

import Dashboard from "./pages/Dashboard";

import Supportchat from "./pages/SupportChat";

import AddJobPost from "./pages/AddJobPost";

import CustomerManagement from "./pages/CustomerManagement";

import AccountActivation from "./pages/AccountActivation";
import ListingManagement from "./pages/ListingManagement";

import Chats from "./pages/Chats";
import AddServices from "./pages/AddServices";
import Salesrep from "./pages/Salesrep";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";

import AllProfile from "./pages/AllProfile";
import Viewjob from "./pages/ViewJob";
import ManageChat from "./pages/Managechat";
import UserProfile from "./pages/UserProfile";
import BuisnessVerification from "./pages/BuisnessVerification";
import UserManagementActive from "./pages/UserManagementActive";
import UserManagementBanned from "./pages/UserManagementBanned";
import TicketDetail from "./pages/TicketDetail";
import ListingManagementActive from "./pages/ListingManagementActive";
import ListingManagementExpired from "./pages/ListingManagementExpired";

import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import ManageVerification from "./pages/ManageVerification";
import ManageSalesrep from "./pages/ManageSalesrep";
import VerificationProfile from "./pages/VerificationProfile";
import ManageVerificationSeller from "./pages/ManageVerificationSeller";
import VerificationDetail from "./pages/VeificationDetails";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/Otp" element={<LoginOtp />} />
            <Route path="/Forgot-Password" element={<ForgotPassword />} />
            <Route path="/UserManagement" element={<UserManagement />} />

            <Route path="/Support" element={<Support />} />

            <Route path="/Dashboard" element={<Dashboard />} />

            <Route path="/userprofile" element={<UserProfile />} />

            <Route path="/Supportchat" element={<Supportchat />} />

            <Route path="/add-job" element={<AddJobPost />} />

            <Route
              path="/CustomerManagement"
              element={<CustomerManagement />}
            />

            <Route path="/AccountActivation" element={<AccountActivation />} />
            <Route path="/listingmanagement" element={<ListingManagement />} />

            <Route path="/chats" element={<Chats />} />
            <Route path="/AddServices" element={<AddServices />} />
            <Route path="/salesrep" element={<Salesrep />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/AddNotification" element={<AddNotification />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />

            <Route path="/AllProfile" element={<AllProfile />} />
            <Route path="/viewjob" element={<Viewjob />} />
            <Route path="/managechat" element={<ManageChat />} />
            <Route
              path="/buisnessverification"
              element={<BuisnessVerification />}
            />
            <Route
              path="/usermanagementactive"
              element={<UserManagementActive />}
            />
            <Route
              path="/usermanagementbanned"
              element={<UserManagementBanned />}
            />
            <Route path="/ticketdetail/:id" element={<TicketDetail />} />
            <Route
              path="/listingmanagementactive"
              element={<ListingManagementActive />}
            />
            <Route
              path="/listingmanagementexpired"
              element={<ListingManagementExpired />}
            />
          </Route>
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/manage-verification" element={<ManageVerification />} />
          <Route path="/manage-salesrep" element={<ManageSalesrep />} />
          <Route
            path="/verification-profile"
            element={<VerificationProfile />}
          />
          <Route path="/manage-salesrep" element={<ManageSalesrep />} />
          <Route
            path="/manage-verificationSeller"
            element={<ManageVerificationSeller />}
          />
          <Route
            path="/verificationDetail"
            element={<VerificationDetail />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
