import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getUnVerifiedUserList,
  updateAccountVerificationStatus,
  updateVerificationStatus,
  verification,
} from "../Redux/actions/adminactions";
import { toast } from "react-toastify";
import Pagination from "../Components/Pagination";
import logo from "../Assets/Images/Logo.svg";

export default function ManageVerificationSeller() {
  const navigate=useNavigate()
  const [list, setList] = useState(null);
  const [flag, setFlag] = useState(true);
  const [pagination, setpagination] = useState(null);
  const [showd,setshowd] = useState(false)
  const [showr,setshowr] = useState(false)
  const [id,setId] = useState("")

  const [verificationParams, setVerificationParams] = useState({
    page: "1",
    search: "",
    limit: "10",
    userType: "seller",
  });

  const [totalData, setTotalData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchdata = async () => {
      try {
        let verificationList = await dispatch(
          getUnVerifiedUserList(verificationParams)
        );
        setList(verificationList?.payload);
      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, [verificationParams, flag]);

  const handleAccept = async () => {
    console.log(id);
    try {
      let data = await dispatch(
        updateAccountVerificationStatus({ id: id, status: "1" })
      );
      if (data?.payload?.message === "User Verified Successfully") {
        toast.success(data?.payload?.message);
        setFlag(!flag);
        setshowd(false)
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async () => {
    console.log(id);
    try {
      let data = await dispatch(
        updateAccountVerificationStatus({ id: id, status: "0" })
      );

      if (data?.payload?.message === "User Rejected Successfully") {
        toast.success(data?.payload?.message);

        setFlag(!flag);
        setshowr(false)
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePages = (page) => {
    // dispatch(setPageNumber(page));

    setVerificationParams((old) => ({ ...old, page: page }));
  };

  const handlesearch = (e) => {
    setVerificationParams((old) => ({ ...old, search: e.target.value }));
  };
  const handlelimit = (e) => {
    setVerificationParams((old) => ({
      ...old,
      limit: e.target.value ? e.target.value : "10",
    }));
  };

  // useEffect(() => {

  // }, [UserTypeData?.payload?.users, pagination]);

  console.log(list);

  const handleclick = (id) => {
    

    navigate("/verificationDetail", {
      state: {
        id: id,
        
      },
    });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>ACCOUNT VERIFICATION</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    onChange={handlesearch}
                    name="search"
                    value={verificationParams.search}
                    placeholder="Keyword Search.."
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/manage-verification" >
                  Sales Representative
                </Link>
              </li>
              <li >
                <Link to="/manage-verificationSeller" className="active-tab">
                  Business Representative
                </Link>
              </li>
              {/* <li>
                <Link to="/manage-salesrep"> Sales Representative</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {1 + (verificationParams?.page - 1) * verificationParams?.limit}{" "}
                -{" "}
                {list?.users?.length +
                  (verificationParams?.page - 1) *
                    verificationParams.limit}{" "}
                of {list?.totalUsers} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlelimit}
                  value={verificationParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
        <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Full Name</th>

                <th>Email</th>
                <th>Phone Number</th>

               
                <th>Profile Image</th>
                {/* <th>Company Website URL</th>
                <th>Monthly Revenue </th>
                <th>Company LLC Name</th>
                <th>Product and Price Detail</th> */}
                <th className="center-class">Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.users?.map((item, index) => {
                const serialNumber =
                  (verificationParams?.page - 1) * verificationParams?.limit +
                  index +
                  1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td  style={{ cursor: "pointer" }}
                          onClick={() => handleclick(item.id)}>{item?.fullName}</td>
                    <td>{item?.email}</td>

                    <td>{item?.phone || "N/A"}</td>
                    <td className="user-img ">
                          <img
                            src={item?.profileImage ? item?.profileImage : logo}
                          />
                        </td>
                    {/* <td>{item?.companyWebsiteUrl}</td>
                    <td>{item?.monthlyRevenue}</td>
                    <td>{item?.companyLLCName}</td>
                    <td>{item?.desc}</td> */}
                    <td>
                      <div className=" acpt-rect-btn">
                        <Button
                          className="account-btn "
                          style={{ width: "120px" }}
                          onClick={() => {
                            
                            setId(item?.id)
                            // handleAccept(item?.userId)
                            setshowd(true)
                            }}
                        >
                          {item?.accountAdminVerified === 1
                            ? "Accepted"
                            : "Accept"}
                        </Button>{" "}
                        <Button
                          className="account-btn"
                          style={{ width: "120px" }}
                          onClick={() => {
                                // handleReject(item?.userId)
                                setId(item?.id)
                                setshowr(true)
                                }}
                        >
                          {item?.accountAdminVerified === 0
                            ? "Reject"
                            : "Reject"}
                        </Button>{" "}
                      </div>
                    </td>
                  </tr>
                );
              })}

              
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={showd}  className="delete-popup">
        <Modal.Body>
          <h2>
           
          Are You Sure You Want To Approve This Account
             
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setshowd(false)}>
            No
          </Button>
          <Button variant="secondary" 
          onClick={handleAccept}
          
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

          <Modal show={showr}  className="delete-popup">
        <Modal.Body>
          <h2>
           
              Are You Sure You Want To Reject The Approval Of This Account
             
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setshowr(false)}>
            No
          </Button>
          <Button variant="secondary" 
          onClick={handleReject}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        // totalpagination={totalpagination}
        pagination={list?.totalUsers / list?.limit}
        paginationevent={handlePages}
      />
    </Layout>
  );
}
