import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  updateVerificationStatus,
  verification,
} from "../Redux/actions/adminactions";
import { toast } from "react-toastify";
import Pagination from "../Components/Pagination";

export default function BuisnessVerification() {
  const [list, setList] = useState(null);
  const [showd,setshowd] = useState(false)
  const [showr,setshowr] = useState(false)
  const [id,setId] = useState("")
  const [flag, setFlag] = useState(true);
  const [pagination, setpagination] = useState(null);

  const [verificationParams, setVerificationParams] = useState({
    page: "1",
    search: "",
    limit: "10",
    userType: "1",
  });

  const [totalData,setTotalData] = useState(null)
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchdata = async () => {
      try {
        let verificationList = await dispatch(verification(verificationParams));
        setList(verificationList?.payload);
      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, [verificationParams, flag]);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let verificationList = await dispatch(
          verification({
            page: "",
            search: "",
            limit: "",
            userType: "1",
          }));
          setTotalData(verificationList?.payload?.total);

        for (
          let i = 1;
          i <=
          Math.ceil(
            (verificationParams?.search
              ? list?.length
              : verificationList?.payload?.total) /
              verificationParams.limit
          );
          i++
        ) {
          setpagination(i);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, [verificationParams, flag]);

  const handleAccept = async () => {
    
    try {
      let data = await dispatch(
        updateVerificationStatus({ id: id, badgeVerificationStatus: "0" })
      );
      if (
        data?.payload?.message === "Badge verification updated successfully."
      ) {
        toast.success(data?.payload?.message);
        setFlag(!flag);
        setshowd(false)
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async () => {
  
    try {
      let data = await dispatch(
        updateVerificationStatus({ id: id, badgeVerificationStatus: "1" })
      );

      if (
        data?.payload?.message === "Badge verification updated successfully."
      ) {
        toast.success(data?.payload?.message);
        setshowr(false)
        setFlag(!flag);
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePages = (page) => {
    // dispatch(setPageNumber(page));

    setVerificationParams((old) => ({ ...old, page: page }));
  };

  const handlesearch = (e) => {
    setVerificationParams((old) => ({ ...old, search: e.target.value }));
  };
  const handlelimit = (e) => {
    setVerificationParams((old) => ({
      ...old,
      limit: e.target.value ? e.target.value : "10",
    }));
  };

  // useEffect(() => {

  // }, [UserTypeData?.payload?.users, pagination]);

  return (
   
        <Layout>
          <div className="right-top">
            <div className="heading-top">
              <h2>VERIFICATION BADGE</h2>
            </div>
            <hr />
            <Container fluid>
              <div className="cmn-top-fields">
                <Row>
                  <Col xxl={3} xl={3} lg={4} md={4}>
                    <Form.Group>
                      <Form.Control
                        type="search"
                        onChange={handlesearch}
                        name="search"
                        value={verificationParams.search}
                        placeholder="Keyword Search.."
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Container>
            <hr />
            <Container fluid>
              <div className="product-tab">
                <ul>
                  <li>
                    <Link to="/buisnessverification" className="active-tab">
                      Business Owners
                    </Link>
                  </li>
                  <li>
                    <Link to="/salesrep"> Sales Representative</Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
          <Container fluid>
            <div className="results-sec">
              <Row>
                <Col lg={6} className="d-flex align-items-center">
                  <div className="show-results">
                    Showing{" "}
                    {1 + (verificationParams?.page - 1) * verificationParams?.limit} -{" "}
                    {list?.BadgeVerifications?.length +
                      (verificationParams?.page - 1) * verificationParams.limit}{" "}
                    of {totalData} results
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="select-result d-flex align-items-center justify-content-end">
                    <span>Results per page</span>{" "}
                    <Form.Select
                      onChange={handlelimit}
                      value={verificationParams.limit}
                      aria-label="Default select example"
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>

          <Container fluid className="px-0">
            <div class="table-responsive main">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Full Name</th>
                    <th>Phone Number</th>
                    <th>Company Website URL</th>
                    <th>Monthly Revenue </th>
                    <th>Company LLC Name</th>
                    <th>Product and Price Detail</th>
                    <th className="center-class">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {list?.BadgeVerifications?.map((item, index) => {
                    const serialNumber =
                      (verificationParams?.page - 1) *
                        verificationParams?.limit +
                      index +
                      1;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td>{item?.fullName || "N/A"}</td>
                        <td>{item?.phone || "N/A"}</td>
                        <td>{item?.companyWebsiteUrl || "N/A"}</td>
                        <td>{item?.monthlyRevenue || "N/A"}</td>
                        <td>{item?.companyLLCName || "N/A"}</td>
                        <td> <div className="content">{item?.desc || "N/A"}</div></td>
                        <td>
                          <div className="d-flex  acpt-rect-btn">
                            <Button
                              className="account-btn "
                              style={{width:"120px"}}
                              onClick={() => {
                                setId(item?.userId)
                                // handleAccept(item?.userId)
                                setshowd(true)
                                }}
                            >
                              {item?.badgeVerificationStatus === 0
                                ? "Accepted"
                                : "Accept"}
                            </Button>{" "}
                            <Button
                              className="account-btn"
                              style={{width:"120px"}}
                              onClick={() => {
                                // handleReject(item?.userId)
                                setId(item?.userId)
                                setshowr(true)
                                }}
                            >
                              {item?.badgeVerificationStatus === 1
                                ? "Rejected"
                                : "Reject"}
                            </Button>{" "}
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                </tbody>
              </Table>
            </div>
          </Container>

          <Modal show={showd}  className="delete-popup">
        <Modal.Body>
          <h2>
           
          Are You Sure You Want To Accept The Request Of This Account
             
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setshowd(false)}>
            No
          </Button>
          <Button variant="secondary" 
          onClick={handleAccept}
          
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

          <Modal show={showr}  className="delete-popup">
        <Modal.Body>
          <h2>
           
              Are You Sure You Want To Reject The Request Of This Account
             
          </h2>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setshowr(false)}>
            No
          </Button>
          <Button variant="secondary" 
          onClick={handleReject}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

          <Pagination
            // totalpagination={totalpagination}
            pagination={pagination}
            paginationevent={handlePages}
          />
        </Layout>
     
    
  );
}
