import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteJob,
  getAllJobs,
  getJobs,
  jobProfile,
} from "../Redux/actions/adminactions";
import Pagination from "../Components/Pagination";
import { setPageNumber } from "../Redux/reducers/userMgmtSlice";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

export default function ListingManagementExpired() {
  const pagenumberfromRedux = useSelector(
    (state) => state.pageNumber.PageNumber
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState(null);
  const [pagination, setpagination] = useState(null);
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(true);
  const [data, setData] = useState(null);
  const [DeletionId, setDeletionId] = useState("");
  const [jobMgmtParams, setJobMgmtParams] = useState({
    page: pagenumberfromRedux ? pagenumberfromRedux : "1",
    search: "",
    limit: "10",
    status:"2"
  });

  // const data = useSelector((state) => state.jobs.alljobs);
  // console.log(data,'dlice');

  console.log(jobsData);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let alljobs = await dispatch(getAllJobs());

        setData(alljobs);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, []);

  const handleShow = (id) => {
    setDeletionId(id);

    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        let jobsData = await dispatch(getJobs(jobMgmtParams));
        setJobsData(jobsData?.payload);
        setpagination( Math.ceil(jobsData?.payload?.totalJobs / jobMgmtParams.limit))
      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, [jobMgmtParams, flag]);

  const handleclick = (id) => {
    console.log(id);
    navigate("/viewjob", {
      state: {
        id: id,
      },
    });
  };
  console.log(DeletionId);
  
  const handledelete = async () => {
    try {
      let data = await dispatch(deleteJob(DeletionId));
      console.log(data);
      if (data?.payload?.message === "Job deleted.") {
        toast.success(data?.payload?.message);
        setShow(false);
        setFlag(!flag);
      } else {
        toast.error(data?.payload?.message);
      }
    } catch (error) {
      console.log("Error deleting user");
    }
  };

  const handlePages = (page) => {
    dispatch(setPageNumber(page));

    setJobMgmtParams((old) => ({ ...old, page: page }));
  };

  // useEffect(() => {

  //   if(jobsData?.totalJobs){
  //     for (
  //       let i = 1;
  //       i <= Math.ceil(jobsData?.totalJobs / jobMgmtParams.limit);
  //       i++
  //     ) {
  //       setpagination(i);
  //     }

  //   }
   
  // }, [data, jobMgmtParams]);

  const handlesearch = (e) => {
    setJobMgmtParams((old) => ({ ...old, search: e.target.value }));
  };
  const handlelimit = (e) => {
    setJobMgmtParams((old) => ({
      ...old,
      limit: e.target.value ? e.target.value : "10",
    }));
  };

  console.log(jobsData)

  return (
    <>
     
        <Layout>
          <div className="right-top">
            <div className="heading-top">
              <h2>Posted Jobs</h2>
            </div>
            <hr />
            <Container fluid>
              <div className="cmn-top-fields">
                <Row>
                  <Col xxl={3} xl={3} lg={4} md={4}>
                    <Form.Group>
                      <Form.Control
                        type="search"
                        onChange={handlesearch}
                        name="search"
                        value={jobMgmtParams.search}
                        placeholder="Keyword Search.."
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col xxl={3} xl={3} lg={4} md={4}>
                    <Form.Select aria-label="Default select example">
                      <option>Active</option>
                      <option value="1">Turned Off</option>
                      <option value="1">All</option>
                    </Form.Select>
                  </Col> */}
                  <Col
                    xxl={9}
                    xl={9}
                    lg={8}
                    md={8}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div className="cmn-btn">
                      <Link to="/add-job">ADD Post</Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <hr />
            {/* <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  ALL <span></span>
                </Link>
              </li>
              <li>
                <Link to="/ApprovedOrders">
                  Approved <span></span>
                </Link>
              </li>
              <li>
                <Link to="/OngoingOrders">
                  Ongoing <span></span>
                </Link>
              </li>
              <li>
                <Link to="/PendingOrders">
                  Pending <span></span>
                </Link>
              </li>
              <li>
                <Link to="/RejectedOrders">
                  Rejected <span></span>
                </Link>
              </li>
              <li>
                <Link to="/CompletedOrders">
                  Completed <span></span>
                </Link>
              </li>
              <li>
                <Link to="/SubmittedOrders">
                  Submitted/In Queue <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </Container> */}
            <Container fluid>
              <div className="product-tab">
                <ul>
                  <li>
                    <Link to="/listingmanagement">All</Link>
                  </li>
                  <li>
                    <Link to="/listingmanagementactive"> Active</Link>
                  </li>
                  <li>
                    <Link to="/listingmanagementexpired" className="active-tab">
                      Expired
                    </Link>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
          <Container fluid>
            <div className="results-sec">
              <Row>
                <Col lg={6} className="d-flex align-items-center">
                  <div className="show-results">
                  Showing{" "}
                    {1 + (jobMgmtParams?.page - 1) * jobMgmtParams?.limit} -{" "}
                    {jobsData?.jobs?.length +
                      (jobMgmtParams?.page - 1) * jobMgmtParams.limit}{" "}
                    of {jobsData?.totalJobs}  results
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="select-result d-flex align-items-center justify-content-end">
                    <span>Results per page</span>{" "}
                    <Form.Select
                      onChange={handlelimit}
                      value={jobMgmtParams.limit}
                      aria-label="Default select example"
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="15">25</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>

          <Container fluid className="px-0">
            <div class="table-responsive main">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Company Name</th>
                    <th>Job post Title</th>
                    <th>Description</th>
                    <th>Remote/In-persons </th>
                    <th>Setter/Close</th>

                    <th>Days Availability requirement</th>

                    <th>Monthly OTE</th>
                    <th>Job Status</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {jobsData?.jobs?.map((item, index) => {
                    const serialNumber =
                      (jobMgmtParams?.page - 1) * jobMgmtParams?.limit +
                      index +
                      1;
                    return (
                      <tr>
                        <td>{serialNumber}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleclick(item.id)}
                        >
                          {item?.companyName || "N/A"}
                        </td>
                        <td>{item?.jobTitle}</td>
                        <td>
                          <div className="content">{item.description}</div>
                        </td>
                        <td>{item?.remoteOrInPerson}</td>
                        <td>{item?.repsNeeded}</td>

                        <td className="days-show">
                          {item?.workDays?.map((x) => {
                            let data;
                            if (x?.value?.length > 4) {
                              data = x?.value?.slice(0, 3);
                            } else {
                              data = x?.value;
                            }

                            return (
                              <p>
                                {data}
                                {item?.workDays[item?.workDays?.length - 1] !==
                                x
                                  ? ","
                                  : ""}
                              </p>
                            );
                          })}
                        </td>

                        <td>{item.monthlyOTE}</td>
                        <td>{item?.status == 1 ? "Active" : null}</td>
                        <td className="order-btn">
                          <button
                            type="button"
                            onClick={() => handleShow(item?.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {/* <table class="table-cmn table table-sm">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Company Name</th>
                <th>Job post Title</th>
                <th>Description</th>
                <th>Remote/In-persons </th>
                <th>Setter/Close</th>
                <th>Days Availability requirement</th>
                <th>Monthly OTE</th>
                <th>Job Status</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <a href="/viewjob">ABC</a>
                </td>
                <td>New job</td>
                <td>
                  <div className="content">Hi</div>
                </td>
                <td>Remote</td>
                <td>Deal Closer (AE)</td>
                <td className="days-show">
                  <p>Monday</p>
                  <p>Tuesday</p>
                  <p>Wednesday</p>
                  <p>Thursday</p>
                  <p>Friday</p>
                </td>
                <td>20K-30KK</td>
                <td>Active</td>
                <td class="order-btn">
                  <button type="button">Delete</button>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <a href="/viewjob">ABC</a>
                </td>
                <td>Testing</td>
                <td>
                  <div className="content">
                    hghhhjhhghhjhjhuhuhuhuhuhuhuhuhuhuhu
                  </div>
                </td>
                <td>Remote</td>
                <td>Appointment Setter (SDR)</td>
                <td className="days-show">
                  <p>Mon</p>
                  <p>Tue</p>
                  <p>Wed</p>
                  <p>Thu</p>
                  <p>Fri</p>
                </td>
                <td>1k-3K</td>
                <td>Active</td>
                <td class="order-btn">
                  <button type="button">Delete</button>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <a href="/viewjob">ABC</a>
                </td>
                <td>Testing</td>
                <td>dftdfdfdfdf</td>
                <td>In Person</td>
                <td>Deal Closer (AE)</td>
                <td className="days-show">
                  <p>Mon</p>
                  <p>Tue</p>
                  <p>Wed</p>
                  <p>Thu</p>
                  <p>Fri</p>
                </td>
                <td>1k-3K</td>
                <td>Active</td>
                <td class="order-btn">
                  <button type="button">Delete</button>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <a href="/viewjob">ABC</a>
                </td>
                <td>Puzzle Huddle</td>
                <td>
                  <div className="content">
                    We claim to hate cliches, but our brains inherently trust
                    that with which it is familiar, so this isn’t a bad way to
                    go. Plus, not all common phrases are annoying cliches. Try
                    out idioms, famous quotes or lyrics, or just small bits from
                    them. We claim to hate cliches, but our brains inherently
                    trust that with which it is familiar, so this isn’t a bad
                    way to go. Plus, not all common phrases are annoying
                    cliches. Try out idioms, famous quotes or lyrics, or just
                    small bits from them.
                  </div>
                </td>
                <td>Remote</td>
                <td>Deal Closer (AE)</td>
                <td className="days-show">
                  <p>Tue</p>
                  <p>Mon</p>
                  <p>Wed</p>
                  <p>Thu</p>
                  <p>Fri</p>
                </td>
                <td>3K-6K</td>
                <td>Active</td>
                <td class="order-btn">
                  <button type="button">Delete</button>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <a href="/viewjob">ABC</a>
                </td>
                <td>The Knickknack Shack</td>
                <td>
                  <div className="content">
                    {" "}
                    We claim to hate cliches, but our brains inherently trust
                    that with which it is familiar, so this isn’t a bad way to
                    go. Plus, not all common phrases are annoying cliches. Try
                    out idioms, famous quotes or lyrics, or just small bits from
                    them. We claim to hate cliches, but our brains inherently
                    trust that with which it is familiar, so this isn’t a bad
                    way to go. Plus, not all common phrases are annoying
                    cliches. Try out idioms, famous quotes or lyrics, or just
                    small bits from them.
                  </div>
                </td>
                <td>Remote</td>
                <td>Appointment Setter (SDR)</td>
                <td className="days-show">
                  <p>Tue</p>
                  <p>Mon</p>
                  <p>Wed</p>
                  <p>Thu</p>
                  <p>Fri</p>
                </td>
                <td>6K-12K</td>
                <td>Active</td>
                <td class="order-btn">
                  <button type="button">Delete</button>
                </td>
              </tr>
            </tbody>
          </table> */}
            </div>
          </Container>
          <Modal show={show} onHide={handleClose} className="delete-popup">
            <Modal.Body>
              <h2>Are You Sure You Want To delete This Job</h2>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="secondary" onClick={handledelete}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <Pagination pagination={pagination} paginationevent={handlePages} />
        </Layout>
      
    </>
  );
}
