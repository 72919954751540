import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { addjob, getAllData } from "../Redux/actions/adminactions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


export default function AddJobPost() {
  const [jobParams, setJobParams] = useState({
    page: "",
    search: "",
    limit: "",
    role: "",
  });
  const [allData, setAllData] = useState();
  const dispatch = useDispatch();
  const [workingDays, setWorkingDays] = useState();
  const navigate = useNavigate();


  const [addJob, setAddJob] = useState({
    userId:"",
    companyName: "",
    jobTitle: "",
    description: "",
    remoteorInPerson: "",
    repsType: "",
    averageOfferPrice: "",
    recurringPayment: "",
    commission: "",
    days: [],
    industryCategory: "",
    monthlyOTE: "",
  });
  const [error, seterror] = useState({
    companyNameerr: "",
    jobTitleerr: "",
    descriptionerr: "",
    remoteorInPersonerr: "",
    repsTypeerr: "",
    averageOfferPriceerr: "",
    recurringPaymenterr: "",
    commissionerr: "",
    dayserr: [],
    industryCategoryerr: "",
    monthlyOTEerr: "",
  });

  useEffect(() => {
    // dispatch(setUserType(userType));
    const fetchData = async () => {
      try {
        let alluser = await dispatch(
          getAllData({ ...jobParams, role: "seller" })
        );

        setAllData(alluser);
      } catch (error) {
        console.error("error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(allData?.payload?.users,"=================")

  const handlechange = (e) => {
    setAddJob((old) => ({ ...old, [e?.target?.name]: e?.target?.value }));
    // setAddJob((old) => ({ ...old, userId: allData?.payload?.users }));
    // setAddJob((old) => (
    //   { ...old, days: workingDays }
    //   ));

    seterror((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };

  const handledropdown=(e)=>{
  
    
    const [id, companyName] = e.target.value.split(',');
    setAddJob((old) => ({ ...old,userId : id }));
    setAddJob((old) => ({ ...old, companyName: companyName }));
    seterror((old) => ({ ...old, companyNameerr: "" }));
  }



  const handleclick = () => {
    validations();
  };
  const addworkingdays = (e) => {
    console.log("first");

    setWorkingDays((old) => ({ ...old, label: e.target.value }));

    setWorkingDays((old) => ({ ...old, value: e.target.value }));
    seterror((old) => ({ ...old, dayserr: "" }));
  };

  useEffect(() => {
    let data = addJob?.days?.push(workingDays);
    let newdata = addJob?.days?.filter((item) => item != undefined);
    setAddJob((old) => ({ ...old, days: newdata }));
  }, [workingDays]);

  const validations = async () => {
    let isvalid = true;
    let err = {
      companyNameerr: "",
      jobTitleerr: "",
      descriptionerr: "",
      remoteorInPersonerr: "",
      repsTypeerr: "",
      averageOfferPriceerr: "",
      recurringPaymenterr: "",
      commissionerr: "",
      dayserr: [],
      industryCategoryerr: "",
      monthlyOTEerr: "",
    };

    if (!addJob.companyName) {
      err.companyNameerr = "Company Name is required";
      isvalid = false;
    }

    if (!addJob.jobTitle) {
      err.jobTitleerr = "Job Title is required";
      isvalid = false;
    }
    if (!addJob.description) {
      err.descriptionerr = "Description is required";
      isvalid = false;
    }
    if (!addJob.remoteorInPerson) {
      err.remoteorInPersonerr = "Please select an option";
      isvalid = false;
    }
    if (!addJob.repsType) {
      err.repsTypeerr = "Please select the type of rep needed";
      isvalid = false;
    }
    if (!addJob.averageOfferPrice) {
      err.averageOfferPriceerr = "Offer price is required";
      isvalid = false;
    }
    if (!addJob.recurringPayment) {
      err.recurringPaymenterr = "Please select an payment type";
      isvalid = false;
    }
    if (!addJob.commission) {
      err.commissionerr = "Please select a commission type";
      isvalid = false;
    }
    if (!addJob.days?.length > 0) {
      err.dayserr = "Please select work days";
      isvalid = false;
    }

    if (!addJob.industryCategory) {
      err.industryCategoryerr = "Please select an industry category";
      isvalid = false;
    }
    if (!addJob.monthlyOTE) {
      err.monthlyOTEerr = "Please select a monthly OTE";
      isvalid = false;
    }
    if (!isvalid) {
      seterror(err);
    }
    if (isvalid) {
      console.log(addJob, "fffffffffffffff");
      try {
        let data = await dispatch(addjob(addJob));
        console.log(data)
        if (data?.payload?.message === "Job added successfully.") {
          toast.success(data?.payload?.message);
          navigate("/listingmanagement");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add New Job</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/listingmanagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8} className="mb-4">
            <div className="customer-form-new">
              <h2>Create New Job Post</h2>
              <hr className="cmn-border" />
              <div className="customer-form-inner">
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Company Details</Form.Label>
                      <Form.Select
                        name="companyName"
                        // value={addJob.companyName}
                        onChange={handledropdown}
                        aria-label="Default select example"
                      >
                        <option>Select</option>
                        {allData?.payload?.users?.map((item, i) => {
                          
                         return( <option key={i}  value={`${item?.id},${item?.companyName}`}>
                            {item?.companyName ? item?.companyName : "ABC"}
                          </option>)
                        })}

                        {/* <option value="1">Abc Company</option>
                        <option value="2">Abc Company</option>
                        <option value="3">Xyz Company</option> */}
                      </Form.Select>
                      <p style={{ color: "red" }}>{error.companyNameerr}</p>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Job Title</Form.Label>
                      <Form.Control
                        value={addJob.jobTitle}
                        name="jobTitle"
                        onChange={handlechange}
                        type="text"
                        placeholder="Job Title"
                      />
                      <p style={{ color: "red" }}>{error.jobTitleerr}</p>
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        value={addJob.description}
                        name="description"
                        onChange={handlechange}
                        as="textarea"
                        rows={3}
                        placeholder="Please add an in depth description of the job
                      requirements, minimum KPI, expectations,
                      responsibilities and anything else candidates need
                      to know about you job post."
                      />
                      <p style={{ color: "red" }}>{error.descriptionerr}</p>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Is this Remote of in person</Form.Label>
                      <Form.Select
                        value={addJob.remoteorInPerson}
                        name="remoteorInPerson"
                        onChange={handlechange}
                        aria-label="Default select example"
                      >
                        <option>Select</option>
                        <option value="Remote">Remote</option>
                        <option value="In-Person">In-Person</option>
                      </Form.Select>
                      <p style={{ color: "red" }}>
                        {error.remoteorInPersonerr}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>What types of reps do you need?</Form.Label>
                      <Form.Select
                        value={addJob.repsType}
                        name="repsType"
                        onChange={handlechange}
                        aria-label="Default select example"
                      >
                        <option>Select</option>
                        <option value="Appointment Setter (SDR)">
                          Appointment Setter (SDR)
                        </option>
                        <option value="Deal Closer (AE)">
                          Deal Closer (AE)
                        </option>
                      </Form.Select>
                      <p style={{ color: "red" }}>{error.repsTypeerr}</p>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>What is you average offer price?</Form.Label>
                      <Form.Select
                        value={addJob.averageOfferPrice}
                        name="averageOfferPrice"
                        onChange={handlechange}
                        aria-label="Default select example"
                      >
                        <option>Select</option>
                        <option value="1K-3K">1K-3K</option>
                        <option value="3K-6K">3K-6K</option>
                        <option value="6K-10K">6K-10K</option>
                        <option value="10K-25K">10K-25K</option>
                        <option value="25K-75K">25K-75K</option>
                        <option value="75K-150K">75K-150K</option>
                        <option value="150K+">150K+</option>
                      </Form.Select>
                      <p style={{ color: "red" }}>
                        {error.averageOfferPriceerr}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Is this a one-time or recurring payment?
                      </Form.Label>
                      <Form.Select
                        value={addJob.recurringPayment}
                        name="recurringPayment"
                        onChange={handlechange}
                        aria-label="Default select example"
                      >
                        <option>Select</option>
                        <option value="One Time">One Time</option>
                        <option value="Recurring">Recurring</option>
                      </Form.Select>
                      <p style={{ color: "red" }}>
                        {error.recurringPaymenterr}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        What commission do you want to offer?
                      </Form.Label>
                      <Form.Control
                        value={addJob.commission}
                        name="commission"
                        onChange={handlechange}
                        type="text"
                        placeholder="Enter commission"
                      />
                      <p style={{ color: "red" }}>{error.commissionerr}</p>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Days</Form.Label>
                      <div className="days-select">
                        <div className="select-inner">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="Monday"
                              name="label"
                              onChange={addworkingdays}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                                Monday
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value="Tuesday"
                              name="label"
                              onChange={addworkingdays}
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Tuesday
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              name="label"
                              onChange={addworkingdays}
                              class="form-check-input"
                              type="checkbox"
                              value="Wednesday"
                              id="flexCheckDefault1"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault1"
                            >
                              Wednesday
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              onChange={addworkingdays}
                              name="workingDays"
                              class="form-check-input"
                              type="checkbox"
                              value="Thursday"
                              id="flexCheckDefault2"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault2"
                            >
                              Thursday
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              onChange={addworkingdays}
                              name="workingDays"
                              class="form-check-input"
                              type="checkbox"
                              value="Friday"
                              id="flexCheckDefault3"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault3"
                            >
                              Friday
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              value="Saturday"
                              onChange={addworkingdays}
                              name="workingDays"
                              class="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault4"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault4"
                            >
                              Saturday
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              name="workingDays"
                              onChange={addworkingdays}
                              class="form-check-input"
                              type="checkbox"
                              value="Sunday"
                              id="flexCheckDefault5"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault5"
                            >
                              Sunday
                            </label>
                          </div>
                        </div>
                      </div>
                      <p style={{ color: "red" }}>{error.dayserr}</p>
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Select industry category of your business
                      </Form.Label>
                      <Form.Select
                        value={addJob.industryCategory}
                        name="industryCategory"
                        onChange={handlechange}
                        aria-label="Default select example"
                      >
                        <option>Select</option>
                        <option value="Coaching and Consulting">
                          Coaching and Consulting
                        </option>
                        <option value="Digital Marketing Agencies">
                          Digital Marketing Agencies
                        </option>
                        <option value="Finance">Finance</option>
                        <option value="Health and Fitness">
                          Health and Fitness
                        </option>
                        <option value="Info Products">Info Products</option>
                        <option value="Insurance">Insurance</option>
                        <option value="Mortgage Loans">Mortgage Loans</option>
                        <option value="SAAS">SAAS</option>
                        <option value="Solar">Solar</option>
                        <option value="Technology">Technology</option>
                        <option value="Web Development">Web Development</option>
                        <option value="Other (Enter by self)">
                          Other 
                        </option>
                      </Form.Select>
                      <p style={{ color: "red" }}>
                        {error.industryCategoryerr}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        What is the monthly OTE (On Track Earnings) for this
                        offer?
                      </Form.Label>
                      <Form.Select
                        value={addJob.monthlyOTE}
                        name="monthlyOTE"
                        onChange={handlechange}
                        aria-label="Default select example"
                      >
                        <option>Select</option>
                        <option value="1K-3K">1K-3K</option>
                        <option value="3K-6K">3K-6K</option>
                        <option value="6K-10K">6K-10K</option>
                        <option value="10K-25K">10K-25K</option>
                        <option value="25K-75K">25K-75K</option>
                        <option value="75K-150K">75K-150K</option>
                        <option value="150K+">150K+</option>
                      </Form.Select>
                      <p style={{ color: "red" }}>{error.monthlyOTEerr}</p>
                    </Form.Group>
                  </Col>
                  {/* <Col lg={12}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Assign Property Manager</Form.Label>

                        <Form.Select aria-label="Default select example">
                          <option>Danny</option>
                          <option value="1">Kevin</option>
                          <option value="2">Sam</option>
                          <option value="3">Sam</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col> */}
                  <Col lg={12} className="d-flex justify-content-center">
                    <button
                      onClick={handleclick}
                      type="button"
                      className="add-btn"
                    >
                      Add
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
