import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import {
  changeSupportStatus,
  getAllSupports,
} from "../Redux/actions/adminactions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Components/Pagination";
import { toast } from "react-toastify";
import { CgLayoutGrid } from "react-icons/cg";

export default function Support() {
  const Allsupports = useSelector((state) => state.Support.allSupports);
  const dispatch = useDispatch();

  const [userMgmtParams, setUserMgmtParams] = useState({
    page: "1",
    search: "",
    limit: "10",
  });
  const [flag,setflag] = useState(true)

  useEffect(() => {
    dispatch(getAllSupports(userMgmtParams));
  }, [userMgmtParams,flag]);

  console.log(Allsupports);
  console.log(Math.ceil(Allsupports?.total / Allsupports?.limit));

  const handlesearch = (e) => {
    setUserMgmtParams((old) => ({ ...old, search: e.target.value }));
  };

  const handlelimit = (e) => {
    setUserMgmtParams((old) => ({
      ...old,
      limit: e.target.value ? e.target.value : "10",
    }));
  };

  const handlePages = (page) => {
    // dispatch(setPageNumber(page));

    setUserMgmtParams((old) => ({ ...old, page: page }));
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>USER SUPPORT</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    onChange={handlesearch}
                    type="search"
                    name="search"
                    value={userMgmtParams.search}
                    placeholder="Keyword Search.."
                  />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  {/* <Link to="#">DOWNLOAD</Link> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 +
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit}{" "}
                -{" "}
                {Allsupports?.supports?.length +
                  (userMgmtParams?.page - 1) * userMgmtParams.limit}{" "}
                of {Allsupports?.total} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlelimit}
                  value={userMgmtParams.limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>Contact number</th>
                <th>MESSAGE </th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {Allsupports?.supports?.map((support, index) => {
                const serialNumber =
                  (userMgmtParams?.page - 1) * userMgmtParams?.limit +
                  index +
                  1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <Link to={`/ticketdetail/${support?._id}`}>
                        {support?.userId?.fullName}
                      </Link>
                    </td>
                    <td>{support?.userId?.email || "N/A"}</td>
                    <td>{support?.userId?.phone || "N/A"}</td>
                    <td><div className="content">{support?.description}</div></td>
                    <td>{support?.status == 0 ? "Opened" : "Closed"}</td>
                    <td className="select-box">
                      <Form.Select
                        onChange={(e) => {
                          dispatch(
                            changeSupportStatus({
                              id: support?._id,
                              status: e.target.value,
                            })
                          ).then(res=>{
                            console.log(res)
                            if(res?.payload?.message =="Support status changed successfully"){
                              toast.success(res?.payload?.message)
                              setflag(!flag)
                            }else{
                              toast.error(res?.payload?.message)
                            }
                          })
                        }}
                        value={support?.status}
                        aria-label="Default select example"
                      >
                        <option value="0">Opened</option>
                        <option value="1">Closed</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        // totalpagination={}
        pagination={Math.ceil(Allsupports?.total / Allsupports?.limit)}
        paginationevent={handlePages}
      />
    </Layout>
  );
}
