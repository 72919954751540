import { configureStore } from "@reduxjs/toolkit";
import { ActivePathSlice, ActivepageNumberSlice, BannedPathSlice, BannedpageNumberSlice, pageNumberSlice, userMgmtSlice, userTypeSlice } from "./reducers/userMgmtSlice";
import { userActivationSlice, userProfileSlice } from "./reducers/userProfileSlice";

import {DashboardSlice} from "./reducers/DashboardSlice";
import {supportSlice} from "./reducers/supportSlice";

export const store = configureStore({
  reducer: {
    allusers: userMgmtSlice.reducer,
    userprofile: userProfileSlice.reducer,
    
    dashboardData:DashboardSlice.reducer,
    userdatatype:userTypeSlice.reducer,
    pageNumber:pageNumberSlice.reducer,
    bannedpageNumber:BannedpageNumberSlice.reducer,
    activepageNumber:ActivepageNumberSlice.reducer,
    // banpath:BannedPathSlice.reducer,
    activepath:ActivePathSlice.reducer,
    Activation:userActivationSlice.reducer,
    Support:supportSlice.reducer
  },
});
