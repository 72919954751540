import { createSlice } from "@reduxjs/toolkit";
import { dashboardData, getAllSupports, getSupportById } from "../actions/adminactions";

const initialState={
    allSupports:null,
    getSupportById:null
}


export const supportSlice=createSlice({
    name:"DashboardSlice",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllSupports.fulfilled,(state,action)=>{
           state.allSupports=action.payload

        })
        builder.addCase(getSupportById.fulfilled,(state,action)=>{
           state.getSupportById=action.payload

        })
    }

})

export default supportSlice.reducer